import styled from 'styled-components';

import { media } from '../../style/helper';

export const DocumentsWrap = styled.div`
  margin-top: 30px;

  .filter-documents {
    display: flex;
    gap: 10px;
    margin-top: 25px;
    .status-filter {
      display: flex;
      gap: 10px;
    }
  }
  .status-filter.phone {
    display: none;
  }
  .all-documents {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 24px;
    .document-item {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      display: flex;
      margin-bottom: 20px;
      width: 49%;
      ${media.phone`
      width:auto;
  `}
      .document-type {
        align-items: center;
        background-color: #f08326;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        padding: 0px 15px;
      }
      .document-type.completed {
        background-color: #15c183;
      }
      .document-infos {
        width: 100%;

        .document-info {
          cursor: pointer;
          display: flex;
          gap: 50px;
          margin: 11px 16px 0px 16px;
          padding-bottom: 11px;
          width: 90%;
          .download-button {
            align-items: flex-end;
            flex-direction: column;
            display: flex;
            width: 100%;

            a {
              justify-content: center;
              align-items: center;
              width: 160px;
              height: 36px;
              font-weight: 500;
              padding: 0;

              ${media.phone`
              width: 100%;
              `}
            }
          }
        }
      }
      .document-buttons {
        align-items: center;
        display: flex;
        gap: 10px;
        margin-left: 70px;
      }
      .btn-link {
        align-items: center;
        display: flex;
        gap: 10px;
        font-weight: 500;
        background-color: #ffffff;
        margin: 6px 0px 0px 0px;
        padding: 5px 20px;
        /* border: 1px solid #dfdfdf; */
        border-radius: 25px;
        font-size: 12px;
        ${media.phone`
        border: 1px solid #dfdfdf;
        `}
      }
    }
  }

  .all-documents.phone {
    display: none;
  }
  ${media.phone`
    padding:0px 20px;
    .status-filter.web{
      display:none;
    }
    .status-filter.phone {
    display: block;
  }
    .filter-documents{
      flex-direction:column;
    }
    .shipments-filter {
      width: 100%;
    }
    .no-filter{
      width:100%;
    }
    .all-documents.phone {
    display: block;
    .document-buttons-phone{
      display:flex;
    }
  }
  .all-documents.web {
    display: none;
  }
  `}
`;
