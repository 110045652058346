import React from 'react';

function BackIcon() {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="40" height="40" rx="20" stroke="#1B1B1B" />
      <path
        d="M20.3457 28L14.0001 21.6543L20.3457 15.3087"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0001 21.6544L27.5 21.6544"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { BackIcon };
