import React from 'react';

function CloseIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.3158 14.6842L11 11M11 11L14.6842 7.31578M11 11L14.6842 14.6842M11 11L7.3158 7.31578M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke="#D71920"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { CloseIcon };
