import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Button, Pagination, Typography } from '@/components';
import { ChevronRight } from '@/components/Icons';
import { SearchIcon } from '@/components/Icons/SearchIcon';
import { ShipIcon } from '@/components/Icons/ShipIcon';
import { ShipmentDocumentModal } from '@/components/ShipmentDocumentModal';
import { TextInput } from '@/components/TextInput';

import { useDebounce } from '../../hooks';
import ShipmentService from '../../service/shipments';

import * as S from './style';
function Documents() {
  const [currentPage, setCurrentPage] = useState(1);
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState(
    window?.location?.search?.includes('done')
      ? 'done'
      : window?.location?.search?.includes('active')
      ? 'active'
      : 'all'
  );

  const { t } = useTranslation();
  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
  };

  const shipmentsStatusFilter = [
    { value: 'all', label: t('all') },
    { value: 'active', label: t('activeShipments') },
    { value: 'done', label: t('arrivedShipments') },
    { value: 'isFavorited', label: t('favoriteShipments') }
  ];
  const getData = async params => {
    setIsLoading(true);
    try {
      const req = await ShipmentService.getAll(params);
      setPagination({
        total: req?.last_page,
        hasMore: !!req?.next_page_url
      });

      setShipments(req.data);
    } catch (error) {
      console.error('Data fetch error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    const params = {
      page: currentPage,
      perPage: 10
    };

    if (filter === 'isFavorited') {
      params.isFavorite = true;
    }

    if (filter === 'done' || filter === 'active') {
      params.status = filter;
    }

    if (debouncedSearch) {
      params.search = debouncedSearch;
    }
    getData(params);
  }, [currentPage, debouncedSearch, filter]);
  const [selectedShipment, setSelectedShipment] = useState(null);

  return (
    <S.DocumentsWrap>
      <Typography fontSize={42}>{t('documents')}</Typography>
      <div className="filter-documents">
        <div className="no-filter">
          <TextInput
            placeHolder={t('search')}
            icon={<SearchIcon />}
            onChange={e => {
              setSearch(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        <div className="status-filter phone">
          <Select
            className="shipments-filter"
            classNamePrefix="select"
            defaultValue={shipmentsStatusFilter[0]}
            name="searchShipmentOption"
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={e => {
              setFilter(e.value);
              setCurrentPage(1);
            }}
            options={shipmentsStatusFilter}
          />
        </div>
        <div className="status-filter web">
          <Button
            backgroundColor={filter === 'all' ? '#1B1B1B' : 'transparent'}
            border={filter === 'all' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'all' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'all' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'all' ? '#FFF' : '#1B1B1B'}
            borderRadius={'25px'}
            onClick={() => {
              setFilter('all');
            }}
          >
            {t('allList')}
          </Button>
          <Button
            borderRadius={'25px'}
            backgroundColor={filter === 'active' ? '#1B1B1B' : 'transparent'}
            border={filter === 'active' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'active' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'active' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'active' ? '#FFF' : '#1B1B1B'}
            onClick={() => {
              setFilter('active');
            }}
          >
            {t('activeShipments')}
          </Button>
          <Button
            backgroundColor={filter === 'done' ? '#1B1B1B' : 'transparent'}
            borderRadius={'25px'}
            border={filter === 'done' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'done' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'done' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'done' ? '#FFF' : '#1B1B1B'}
            onClick={() => {
              setFilter('done');
            }}
          >
            {t('arrivedShipments')}
          </Button>
          <Button
            onClick={() => {
              setFilter('isFavorited');
            }}
            backgroundColor={filter === 'isFavorited' ? '#1B1B1B' : 'transparent'}
            borderRadius={'25px'}
            border={filter === 'isFavorited' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'isFavorited' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'isFavorited' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'isFavorited' ? '#FFF' : '#1B1B1B'}
          >
            {t('favoriteShipments')}
          </Button>
        </div>
      </div>
      <div className="all-documents web">
        {shipments?.map((item, index) => (
          <div
            className="document-item"
            style={{
              borderColor: item.shipment_status === 'done' ? '#15C183' : '#DFDFDF'
            }}
            key={index}
          >
            <div className={`document-type ${item.shipment_status === 'done' ? 'completed' : ''}`}>
              <ShipIcon />
            </div>
            <div className="document-infos" style={{}}>
              <a href={`/shipment-detail/${item.shipment_code}`} className="document-info">
                <div className="no-info">
                  <Typography color={'#676767'} fontSize={14}>
                    {t('bookingNumber')}
                  </Typography>
                  <Typography fontSize={22} fontWeight={400}>
                    {item.shipment_number}
                  </Typography>
                </div>
              </a>
            </div>
            <div
              className="document-infos"
              style={{
                marginLeft: 'auto'
              }}
            >
              <div className="document-info">
                <div className="download-button">
                  <a
                    className="btn-link"
                    onClick={() => {
                      setSelectedShipment(item);
                    }}
                  >
                    {t('seeDocuments')} <ChevronRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="all-documents phone">
        {shipments?.map((item, index) => (
          <div
            className="document-item"
            style={{
              borderColor: item.shipment_status === 'done' ? '#15C183' : '#DFDFDF'
            }}
            key={index}
          >
            <div className={`document-type ${item.shipment_status === 'done' ? 'completed' : ''}`}>
              <ShipIcon />
            </div>
            <div className="document-infos">
              <div
                className="document-info"
                style={{
                  borderBottom: '1px solid #DFDFDF'
                }}
              >
                <div
                  className="no-info"
                  onClick={() => {
                    window.location.href = `/shipment-detail/${item.shipment_code}`;
                  }}
                >
                  <Typography color={'#676767'} fontSize={14} fontWeight={300}>
                    {t('bookingNumber')}
                  </Typography>
                  <Typography fontSize={22}>{item.shipment_number}</Typography>
                </div>
              </div>
              <div className="document-buttons-phone">
                <div className="document-info">
                  <div className="download-button">
                    <a
                      className="btn-link"
                      onClick={() => {
                        setSelectedShipment(item);
                      }}
                    >
                      {t('seeDocuments')} <ChevronRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!isLoading && shipments?.length === 0 && (
        <Typography textAlign={'center'} fontSize={20} color={'#D71A21'}>
          {t('cantFindShipment')} 🔴
        </Typography>
      )}
      {!isLoading && shipments?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={pagination?.total}
          onPageChange={handlePageChange}
          hasMore={pagination?.hasMore}
        />
      )}
      <ShipmentDocumentModal
        selectedShipment={selectedShipment}
        setSelectedShipment={setSelectedShipment}
      />
    </S.DocumentsWrap>
  );
}

export { Documents };
