import React from 'react';

function SearchIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.95581 9.96888L13 13M6.24986 3.25C7.90667 3.25 9.24978 4.59315 9.24978 6.25M11.4997 6.25C11.4997 3.35051 9.14927 1 6.24986 1C3.35044 1 1 3.35051 1 6.25C1 9.1495 3.35044 11.5 6.24986 11.5C9.14927 11.5 11.4997 9.1495 11.4997 6.25Z"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { SearchIcon };
