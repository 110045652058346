import React from 'react';

function NotificationsIcon({ width, height }) {
  return (
    <svg
      width={width ? width : '19'}
      height={height ? height : '22'}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7273 17.1111V17.6667C12.7273 19.8758 11.1639 21 9.2353 21C7.30673 21 5.74332 19.8758 5.74332 17.6667V17.1111M14.5146 6.1282C14.5146 3.29598 12.151 1 9.23529 1C6.3196 1 3.95596 3.29598 3.95596 6.1282C3.95596 7.19544 4.16575 8.05796 3.54604 9.97436C3.31787 10.6799 2.39043 12.1118 1.41181 13.509C0.350026 15.025 1.46179 17.1111 3.37215 17.1111C7.28091 17.1111 11.1897 17.1111 15.0984 17.1111C17.0088 17.1111 18.1206 15.025 17.0588 13.509C16.0802 12.1118 15.1527 10.6799 14.9245 9.97436C14.3048 8.05796 14.5146 7.19544 14.5146 6.1282Z"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { NotificationsIcon };
