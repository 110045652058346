import React from 'react';

function InProgressIcon({ stroke }) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1665 4.85023V10.1134C11.1665 10.6947 11.6378 11.166 12.2191 11.166L17.4823 11.166M11.1665 21.166C5.64366 21.166 1.1665 16.6889 1.1665 11.166C1.1665 5.64317 5.64366 1.16602 11.1665 1.16602C16.6894 1.16602 21.1665 5.64317 21.1665 11.166C21.1665 16.6889 16.6894 21.166 11.1665 21.166Z"
        stroke={stroke ? stroke : '#1B1B1B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { InProgressIcon };
