import axios from 'axios';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    }
  }
});

instance.interceptors.request.use(request => {
  const currentUser = JSON.parse(localStorage.getItem('user'));

  return {
    ...request,
    headers: {
      ...request.headers,
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${currentUser.token}`,
      'Accept-Language': i18next?.language || Cookies.get('Accept-Language') || 'tr'
      // 'Content-Type': 'application/json',
    }
  };
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('user');

      window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default instance;
