import styled from 'styled-components';
const ModalBackground = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  padding: 40px;
  position: relative;
  width: ${props => props.width}px;
  h2 {
    font-size: 36px;
  }
  p {
    margin-top: 24px;
  }
`;

const CloseButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  gap: 8px;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;

  span {
    border: 1px solid #000;
    border-radius: 100%;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
`;

const ModalContent = styled.div``;

export { CloseButton, ModalBackground, ModalContainer, ModalContent };
