import React from 'react';

import { Container } from '@/components';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { AuthLayout } from '@/layouts';
import { Documents } from '@/layouts/Documents/Documents';

function DocumentsPage() {
  return (
    <AuthLayout>
      <Header />
      <Container>
        <Documents />
      </Container>
      <Footer />
    </AuthLayout>
  );
}

export { DocumentsPage };
