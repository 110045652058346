import styled from 'styled-components';

import { media } from '../../style/helper';

const AuthWrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
`;
const ImageWrapper = styled.div`
  max-height: 100%;
  img {
    object-fit: cover;
  }
  width: 50%;
  ${media.phone`
      display:none;`}
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 50%;
  ${media.phone`
      width:100%;`}
  img {
    margin-left: 25px;
  }
  .auth-footer {
    border-top: 1px solid #ededed;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: auto;
    padding: 20px;
    a {
      text-decoration: none;
    }
    ${media.phone`
    justify-content: center;
`}
  }
`;
const FormArea = styled.div`
  padding: 52px 132px 0px 100px;
  ${media.phone`
      padding:20px;`}
  .forgot-password {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 17px;
    .remember-me {
      align-items: center;
      display: flex;
      gap: 9px;
    }
    a {
      text-decoration-color: #1b1b1b;
      text-decoration: underline;
    }
    ${media.phone`
    .forgot-button-web{
      display:none;
    }
    `}
  }
  .submit-sign-in {
    margin-top: 32px;
  }
  .submit-sign-in.phone {
    display: none;
  }
  .forgot-button-phone {
    display: none;
  }
  ${media.phone`
      .submit-sign-in.web{
      display:none;
    }
    .forgot-button-phone {
    display: block;
  }
      .submit-sign-in.phone{
      display:block;
      button{
        width: 100%;
        justify-content:center;
      }
    }
    `}
  .sign-up-area {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    margin-top: 60px;
    padding: 25px 62px 25px 27px;
  }
  ${media.phone`
   
    .sign-up-area{
      padding:13px;
    }
    .sign-up-area div:nth-child(1){
      font-size:22px;
    }
    .sign-up-area div:nth-child(2){
      font-size:14px;
    }
    .sign-up-area button{
        width: 100%;
        justify-content:center
    }
    `}
`;

const mailCodeWrapper = styled.div`
  padding: 52px 132px 0px 100px;

  .resend-code {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
      color: black;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  ${media.phone`
      padding:20px;`}
  .mail-code-actions {
    display: flex;
    gap: 16px;
    ${media.phone`
      flex-direction:column;
    gap: 0px;
    button{
      justify-content:center;
      margin-top:10px;
      padding:15px 30px;

    }
      `}
  }
`;

export { AuthWrapper, FormArea, FormWrapper, ImageWrapper, mailCodeWrapper };
