import styled from 'styled-components';

const StyledPagination = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .pagination__button {
    border: 1px solid #dfdfdf;
    padding: 8px 11px;
    font-weight: bold;
    margin: 0 4px;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .pagination__button:disabled {
    cursor: not-allowed;
    background-color: #dfdfdf;
  }

  .pagination__button.active {
    border-color: #1b1b1b;
  }
`;

export { StyledPagination };
