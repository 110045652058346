import React from 'react';

function SignOutIcon() {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5556 12.1429C12.5556 13.1685 11.6933 14 10.6296 14H2.92593C1.86227 14 1 13.1685 1 12.1429L1 2.85714C1 1.83147 1.86227 1 2.92593 1L10.6296 1C11.6933 1 12.5556 1.83147 12.5556 2.85714M8.94444 7.49989H18.3333M18.3333 7.49989L15.5327 10.3889M18.3333 7.49989L15.5327 4.61111"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { SignOutIcon };
