/* eslint-disable react/no-children-prop */
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@/components';
import { BackIcon, EmailIcon } from '@/components/Icons';
// import { PhoneIcon } from '@/components/Icons/PhoneIcon';
import { RightArrowIcon } from '@/components/Icons/RightArrowIcon';
import { VerificationIcon } from '@/components/Icons/VerificationIcon';
import LanguageDropdown from '@/components/LanguageDropdown';
import { TextInput } from '@/components/TextInput';

import * as S from './style';
import { use } from 'i18next';

function SignInPage() {
  const [email, setEmail] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isSubmitMail, setIsSubmitMail] = useState(false);
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(60);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const handleSubmitEmail = () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/login`,
          {
            email: email
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )
        .then(async () => {
          setIsSubmitMail(true);
          setTimeLeft(60);
          setTimeIsUp(false);
          setErrorText('');
        })
        .catch(() => {
          setErrorText(t('userNotFound'));
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handeOtpCode = () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/claim-otp?email=${encodeURIComponent(
            email
          )}&otp=${otpCode}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )
        .then(async response => {
          setErrorText('');
          const userData = {
            token: response.data.token,
            id: response.data.user.id,
            name: response.data.user.name,
            surname: response.data.user.surname,
            email: response.data.user.email,
            phoneNumber: response.data.user.phone_number,
            birthday: response.data.user.birthday || null,
            role: response.data.user.role,
            companyPhone: response.data.user.company_phone
          };
          localStorage.setItem('user', JSON.stringify(userData));
          window.location.href = '/';
        })
        .catch(() => {
          setErrorText(t('otpCodeError'));
        });
    } catch (error) {
      console.log(error);
    }
    // setIsSubmitMail(true);
  };

  // if timeLeft is 0 then set timeIsUp to true
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeIsUp(true);
    }
  }, [timeLeft]);

  return (
    <S.AuthWrapper>
      <S.ImageWrapper>
        <a href="/">
          <img src="./images/auth-image.svg" alt="Orbis Auth" width={'100%'} height={'100%'} />
        </a>
      </S.ImageWrapper>
      <S.FormWrapper className="auth-content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 20px'
          }}
        >
          <a href="/">
            <img src="./images/orbis-logo.svg" alt="Orbis Logo" width={80} height={80} />
          </a>
          <div style={{}}>
            <LanguageDropdown hasText />
          </div>
        </div>
        {!isSubmitMail && (
          <S.FormArea>
            <Typography fontSize={42} fontWeight={300} margin={'0px 0px 11px 0px'}>
              {t('login')}
            </Typography>
            {errorText && (
              <Typography fontSize={16} color={'#B43E3B'} textAlign={'right'} margin={'3px 0px'}>
                {errorText}
              </Typography>
            )}
            <TextInput
              placeHolder={'Email Adresiniz'}
              type={'text'}
              icon={<EmailIcon />}
              onChange={e => setEmail(e.target.value)}
            />

            <div className="forgot-password">
              {/* <div className="remember-me">
                <input type="checkbox" id="remember-me" name="remember-me" value="remember-me" />
                <label htmlFor="remember-me">{t('rememberMe')}</label>
              </div> */}
              <a
                href="https://orbislog.com/tr/irtibat-bilgileri"
                className="forgot-button-web"
                target="_blank"
                rel="noreferrer"
              >
                <Typography fontSize={16} color={'#1B1B1B'}>
                  {t('forgotPassword')}
                </Typography>
              </a>
            </div>
            <div className="submit-sign-in web">
              <Button disabled={!email} borderRadius={'25px'} onClick={handleSubmitEmail}>
                {t('signIn')} <RightArrowIcon />
              </Button>
            </div>
            <div className="submit-sign-in phone">
              <Button borderRadius={'25px'} onClick={handleSubmitEmail}>
                {t('signIn')} <RightArrowIcon />
              </Button>
            </div>
            <div className="forgot-password">
              <a href="#" className="forgot-button-phone">
                <Typography fontSize={16} color={'#1B1B1B'}>
                  {t('forgotPassword')}
                </Typography>
              </a>
            </div>
            <div className="sign-up-area">
              <Typography fontSize={24} fontWeight={500}>
                {t('noAccount')}
              </Typography>
              <Typography fontSize={16} fontWeight={300} margin={'7px 0px 0px 0px'}>
                {t('signUpInfo')}
              </Typography>
              <Button
                backgroundColor={'#FFFFFF'}
                color={'#1B1B1B'}
                margin={'27px 0px 0px 0px'}
                border={'1px solid #DFDFDF'}
                fontWeight={500}
                borderRadius={'20px'}
                onClick={() => (window.location.href = '/sign-up')}
              >
                {t('signUp')}
              </Button>
            </div>
          </S.FormArea>
        )}
        {isSubmitMail && (
          <S.mailCodeWrapper>
            <span onClick={() => setIsSubmitMail(false)} style={{ cursor: 'pointer' }}>
              <BackIcon />
            </span>
            <Typography fontSize={42} fontWeight={300} margin={'0px 0px 11px 0px'}>
              {t('emailCode')}
              <Typography fontWeight={500}>{email}</Typography>
              <Typography fontWeight={400}>{t('emailCodeInfo')}</Typography>
            </Typography>
            {errorText && (
              <Typography fontSize={16} color={'#B43E3B'} textAlign={'right'} margin={'3px 0px'}>
                {errorText}
              </Typography>
            )}
            <TextInput
              placeHolder={t('otpCode')}
              type={'text'}
              showCountdown={true}
              timeLeft={timeLeft}
              setTimeLeft={setTimeLeft}
              icon={<VerificationIcon />}
              onChange={e => setOtpCode(e.target.value)}
            />
            <div className="mail-code-actions">
              <Button
                disabled={!otpCode}
                borderRadius={'25px'}
                margin={'20px 0px 0px 0px'}
                onClick={handeOtpCode}
              >
                {t('continue')}
                <RightArrowIcon />
              </Button>
              <Button
                borderRadius={'25px'}
                backgroundColor={'#FFFFFF'}
                color={'#1B1B1B'}
                border={'1px solid #DFDFDF'}
                margin={'20px 0px 0px 0px'}
                onClick={handleSubmitEmail}
                disabled={!timeIsUp}
                className="resend-code"
              >
                {t('resendCode')}
              </Button>
            </div>
          </S.mailCodeWrapper>
        )}
        <div className="auth-footer">
          <a href="">
            <Typography fontSize={'12px'} fontWeight={500} color={'#9795B5'}>
              {t('privacyPolicy')}
            </Typography>
          </a>
        </div>
      </S.FormWrapper>
    </S.AuthWrapper>
  );
}

export { SignInPage };
