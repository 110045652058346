import React from 'react';

import { Container } from '@/components';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { AuthLayout } from '@/layouts';
import Home from '@/layouts/Home';

function HomePage() {
  return (
    <AuthLayout>
      <Header />
      <Container>
        <Home />
      </Container>
      <Footer />
    </AuthLayout>
  );
}

export { HomePage };
