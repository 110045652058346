import axios from './index';

const getUserProfile = async () => {
  const res = await axios.get(`/user/me`);
  return res;
};
const getUserNotifications = async () => {
  const res = await axios.get(`/notifications/web`);
  return res;
};
const updateUserProfile = async params => {
  const res = await axios.put(`/user/me`, params);
  return res;
};
const logout = async () => {
  const res = await axios.post(`/logout`).then(() => {
    localStorage.removeItem('user');
    window.location.href = '/sign-in';
  });
  return res;
};

const UserService = {
  getUserProfile,
  updateUserProfile,
  getUserNotifications,
  logout
};

export default UserService;
