import styled from 'styled-components';

export const loader = styled.div`
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .loader-logo {
    width: 200px;
    margin-bottom: 20px;
  }

  .loader-spinner {
    animation: spin 1s linear infinite;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #000;
    height: 40px;
    width: 40px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
