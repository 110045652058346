import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// import { TextInput } from '@/components/TextInput';
import { Typography } from '@/components/Typography';

import ShipmentService from '../../../service/shipments';

import * as S from './style';
// import ReactSelect from 'react-select';

function SearchShipment() {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (inputValue) {
      if (inputValue.length >= 4) {
        const fetchData = async () => {
          try {
            const response = await ShipmentService.getFilteredShipments(inputValue);
            const data = response.data.data.map(item => ({
              value: item.shipment_number,
              label: item.shipment_number
            }));
            setOptions(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      } else {
        setOptions([]);
      }
    }
  }, [inputValue]);

  const handleInputChange = newValue => {
    setInputValue(newValue);
  };
  const handleChange = selectedOption => {
    console.log(selectedOption);
    if (selectedOption) {
      window.location.href = `/shipment-detail/${selectedOption.value}`;
    }
  };
  const customStyles = {
    container: provided => ({
      ...provided,
      width: '100%'
    })
  };
  return (
    <S.SearcHShipmentWrap>
      <Typography fontWeight={200} fontSize={42}>
        {t('follow')}
      </Typography>
      <div className="search-form">
        <Select
          options={options}
          onInputChange={handleInputChange}
          isClearable
          onChange={handleChange}
          placeholder={t('blOrContainerNumber')}
          noOptionsMessage={() => t('noResult')}
          styles={customStyles}
        />
      </div>
    </S.SearcHShipmentWrap>
  );
}

export { SearchShipment };
