import React from 'react';

function NotCompletedIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9" stroke="#969696" strokeWidth="1.5" />
      <path
        d="M7.93009 14.1995H12.0697C12.441 14.1995 12.7363 13.894 12.6964 13.5421C12.4386 11.2744 10.8999 11.3119 10.8999 10.2994C10.8999 9.28693 12.4582 9.34318 12.6962 7.05679C12.7337 6.70467 12.441 6.39941 12.0697 6.39941H7.93009C7.55884 6.39941 7.2669 6.70467 7.30365 7.05679C7.54159 9.34318 9.09991 9.26818 9.09991 10.2994C9.09991 11.3307 7.56128 11.2744 7.30365 13.5421C7.26353 13.894 7.55884 14.1995 7.93009 14.1995Z"
        stroke="#969696"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6371 13.5992H8.37139C8.07889 13.5992 7.99639 13.2617 8.20152 13.0525C8.69802 12.5492 9.70021 12.1889 9.70021 11.6117V9.69923C9.70021 9.32704 8.98771 9.04297 8.54689 8.43922C8.47414 8.33966 8.48145 8.19922 8.66633 8.19922H11.3425C11.5002 8.19922 11.5343 8.33853 11.4625 8.43828C11.0281 9.04297 10.3002 9.32516 10.3002 9.69923V11.6117C10.3002 12.1842 11.3448 12.493 11.8077 13.0531C11.9943 13.2788 11.929 13.5992 11.6371 13.5992Z"
        fill="#969696"
      />
    </svg>
  );
}

export { NotCompletedIcon };
