import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ArrowUpIcon } from './Icons/ArrowUpIcon';
import { Typography } from './Typography';
require('dayjs/locale/en');
require('dayjs/locale/tr');

const LanguageBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

const FlagIcon = styled.img`
  height: 15px;
  margin-left: 0px !important;
  margin-right: 8px;
  width: 20px;
`;

const Wrapper = styled.div`
  align-items: center;
  /* background-color: #f5f5f5; */
  border-radius: 20px;
  display: flex;
  padding: 8px;
`;

const DropdownMenu = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  left: 0;
  /* padding: 8px; */
  position: absolute;
  top: 40px;
  width: 120px;
  z-index: 100;
`;

const MenuItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const LanguageDropdown = ({ hasText = false }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = language => {
    Cookies.set('Accept-Language', language, { expires: 365 });
    dayjs.locale(language);

    i18n.changeLanguage(language);
    setIsOpen(false); // Menü kapansın
  };

  return (
    <LanguageBtn onClick={() => setIsOpen(!isOpen)}>
      <Wrapper>
        <FlagIcon src={i18n.language === 'tr' ? '/images/tr.png' : '/images/uk.webp'} />
        {hasText && (
          <Typography fontSize={16}>
            {i18n.language === 'tr' ? 'Türkçe' : 'English'} <ArrowUpIcon />
          </Typography>
        )}
      </Wrapper>

      <DropdownMenu isOpen={isOpen}>
        <MenuItem onClick={() => handleChange('tr')}>
          <FlagIcon src="/images/tr.png" />
          <Typography fontSize={16}>Türkçe</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChange('en')}>
          <FlagIcon src="/images/uk.webp" />
          <Typography fontSize={16}>English</Typography>
        </MenuItem>
      </DropdownMenu>
    </LanguageBtn>
  );
};

export default LanguageDropdown;
