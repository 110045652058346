import dayjs from 'dayjs';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { HomePage, SignInPage, SignUpPage, ShipmentsPage } from './pages';
import { DocumentsPage } from './pages/DocumentsPage';
import { ShipmentDetailPage } from './pages/ShipmentDetailPage';
import GlobalStyle from './style/globalStyles';

import './i18n';
import Cookies from 'js-cookie';
require('dayjs/locale/tr');
require('dayjs/locale/en');

dayjs.locale(Cookies.get('Accept-Language') || 'tr');
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/sign-in',
    element: <SignInPage />
  },
  {
    path: '/sign-up',
    element: <SignUpPage />
  },
  {
    path: '/shipments',
    element: <ShipmentsPage />
  },
  {
    path: '/documents',
    element: <DocumentsPage />
  },
  {
    path: '/shipment-detail/:id', // id parametresi eklendi
    element: <ShipmentDetailPage />
  }
]);

createRoot(document.getElementById('root')).render(
  <>
    <GlobalStyle />
    <RouterProvider router={router} />
  </>
);
