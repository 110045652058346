import React from 'react';

function ShareIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.68257 6.89835L10.3135 4.56433M10.1994 11.7059L5.61066 9.23529M15 3.47059C15 4.83506 13.8939 5.94118 12.5294 5.94118C11.1649 5.94118 10.0588 4.83506 10.0588 3.47059C10.0588 2.10612 11.1649 1 12.5294 1C13.8939 1 15 2.10612 15 3.47059ZM5.94118 8C5.94118 9.36447 4.83506 10.4706 3.47059 10.4706C2.10612 10.4706 1 9.36447 1 8C1 6.63553 2.10612 5.52941 3.47059 5.52941C4.83506 5.52941 5.94118 6.63553 5.94118 8ZM15 12.5294C15 13.8939 13.8939 15 12.5294 15C11.1649 15 10.0588 13.8939 10.0588 12.5294C10.0588 11.1649 11.1649 10.0588 12.5294 10.0588C13.8939 10.0588 15 11.1649 15 12.5294Z"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { ShareIcon };
