import axios from './index';

const getAll = async params => {
  const queryParams = new URLSearchParams(params);
  const res = await axios.get(`/shipments?${queryParams}`);
  return res?.data;
};
const getAllShipments = async (perPage = 5, page = 1) => {
  const res = await axios.get(`/shipments?perPage=${perPage}&page=${page}`);
  return res;
};

const getFilteredShipments = async shipmentNumber => {
  const res = await axios.get(`/shipments?search=${shipmentNumber}`);
  return res;
};

const getShipmentDetail = async id => {
  const res = await axios.get(`/shipments/${id}`);
  return res;
};

const addFavorite = async id => {
  const res = await axios.post(`/favorites`, { shipment_id: id });
  return res;
};

const deleteFavorite = async id => {
  const res = await axios.delete(`/favorites/${id}`);
  return res;
};

const getShipmentDocuments = async id => {
  const res = await axios.get(`/shipments/documents/${id}`);
  return res?.data;
};

const ShipmentService = {
  getAllShipments,
  getShipmentDetail,
  getAll,
  getFilteredShipments,
  deleteFavorite,
  addFavorite,
  getShipmentDocuments
};

export default ShipmentService;
