import React from 'react';

function EditIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.18613 2.27508L11.7389 4.79991M12.0095 1.40441L12.5956 1.99053C13.1348 2.52975 13.1348 3.40399 12.5956 3.94321L4.83867 11.7001C4.6871 11.8517 4.50232 11.9659 4.29896 12.0337L1.45494 12.9817C1.18509 13.0716 0.92836 12.8149 1.01831 12.5451L1.96632 9.70104C2.0341 9.49768 2.1483 9.3129 2.29987 9.16133L10.0568 1.40441C10.596 0.865195 11.4703 0.865197 12.0095 1.40441Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { EditIcon };
