import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Button, Pagination, Typography } from '@/components';
import { ChevronRight, DocumentsIcon, RightArrowIcon } from '@/components/Icons';
import { NotCompletedIcon } from '@/components/Icons/NotCompletedIcon';
import { SearchIcon } from '@/components/Icons/SearchIcon';
import { ShipIcon } from '@/components/Icons/ShipIcon';
import { SuccessIcon } from '@/components/Icons/SuccessIcon';
import { Loader } from '@/components/Loader';
import { ShipmentDocumentModal } from '@/components/ShipmentDocumentModal';
import { TextInput } from '@/components/TextInput';

import { useDebounce } from '../../hooks';
import ShipmentService from '../../service/shipments';

import * as S from './style';

// import DocumentService from '../../service/documents';
function Shipments() {
  const [currentPage, setCurrentPage] = useState(1);
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const debouncedSearch = useDebounce(search, 500);
  const [filter, setFilter] = useState(
    window?.location?.search?.includes('done')
      ? 'done'
      : window?.location?.search?.includes('active')
      ? 'active'
      : 'all'
  );

  const { t } = useTranslation();

  const handlePageChange = page => {
    if (page === currentPage) return;
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(page);
  };

  const shipmentsStatusFilter = [
    { value: 'all', label: 'Tümü' },
    { value: 'active', label: 'Aktif Sevkiyatlar' },
    { value: 'done', label: 'Teslim Edilen Sevkiyatlar' },
    { value: 'isFavorited', label: 'Favori Sevkiyatlar' }
  ];

  const getData = async params => {
    setIsLoading(true);
    try {
      const req = await ShipmentService.getAll(params);
      setPagination({
        total: req?.last_page,
        hasMore: !!req?.next_page_url
      });

      setShipments(req.data);
    } catch (error) {
      console.error('Data fetch error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    const params = {
      page: currentPage,
      perPage: 10
    };

    if (filter === 'isFavorited') {
      params.isFavorite = true;
    }

    if (filter === 'done' || filter === 'active') {
      params.status = filter;
    }

    if (debouncedSearch) {
      params.search = debouncedSearch;
    }
    getData(params);
  }, [currentPage, debouncedSearch, filter]);

  return (
    <S.ShipmentsWrap>
      <Typography fontWeight={300} fontSize={42}>
        {t('myShipments')}
      </Typography>
      <div className="filter-shipments">
        <div className="no-filter">
          <TextInput
            placeHolder={t('blOrContainerNumber')}
            icon={<SearchIcon />}
            onChange={e => {
              setSearch(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        <div className="status-filter phone">
          <Select
            className="shipments-filter"
            classNamePrefix="select"
            defaultValue={shipmentsStatusFilter[0]}
            name="searchShipmentOption"
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={e => {
              setFilter(e.value);
              setCurrentPage(1);
            }}
            options={shipmentsStatusFilter}
          />
        </div>
        <div className="status-filter web">
          <Button
            backgroundColor={filter === 'all' ? '#1B1B1B' : 'transparent'}
            border={filter === 'all' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'all' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'all' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'all' ? '#FFF' : '#1B1B1B'}
            borderRadius={'25px'}
            fontWeight={filter === 'all' ? 600 : 400}
            padding={'7px 28px'}
            fontSize={'14px'}
            onClick={() => {
              setFilter('all');
            }}
          >
            {t('allList')}
          </Button>
          <Button
            borderRadius={'25px'}
            backgroundColor={filter === 'active' ? '#1B1B1B' : 'transparent'}
            border={filter === 'active' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'active' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'active' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'active' ? '#FFF' : '#1B1B1B'}
            fontWeight={filter === 'active' ? 600 : 400}
            padding={'7px 20px'}
            onClick={() => {
              setFilter('active');
            }}
          >
            {t('activeShipments')}
          </Button>
          <Button
            backgroundColor={filter === 'done' ? '#1B1B1B' : 'transparent'}
            borderRadius={'25px'}
            border={filter === 'done' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'done' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'done' ? '#1B1B1B' : '#FFF'}
            hoverColor={filter === 'done' ? '#FFF' : '#1B1B1B'}
            fontWeight={filter === 'done' ? 600 : 400}
            padding={'7px 20px'}
            onClick={() => {
              setFilter('done');
            }}
          >
            {t('arrivedShipments')}
          </Button>
          <Button
            onClick={() => {
              setFilter('isFavorited');
            }}
            backgroundColor={filter === 'isFavorited' ? '#1B1B1B' : 'transparent'}
            borderRadius={'25px'}
            border={filter === 'isFavorited' ? '1px solid #1B1B1B' : '1px solid #DFDFDF'}
            color={filter === 'isFavorited' ? '#FFF' : '#1B1B1B'}
            hoverBackgroundColor={filter === 'isFavorited' ? '#1B1B1B' : '#FFF'}
            padding={'7px 20px'}
            fontWeight={filter === 'isFavorited' ? 600 : 400}
            hoverColor={filter === 'isFavorited' ? '#FFF' : '#1B1B1B'}
          >
            {t('favoriteShipments')}
          </Button>
        </div>
      </div>
      <div className="all-shipments web">
        {isLoading && <Loader />}
        {!isLoading &&
          shipments?.map((item, index) => (
            <div
              className="shipment-item"
              key={index}
              style={{
                borderColor: item.shipment_status === 'done' ? '#15C183' : '#DFDFDF'
              }}
            >
              <div
                className={`shipment-type ${item.shipment_status === 'done' ? 'completed' : ''}`}
              >
                <ShipIcon />
              </div>
              <div
                className="shipment-infos"
                onClick={() => {
                  window.location.href = `/shipment-detail/${item.shipment_code}`;
                }}
              >
                <div
                  className="shipment-info"
                  style={{
                    borderBottom: '1px solid #dfdfdf'
                  }}
                >
                  <div className="no-info">
                    <Typography fontWeight={300} color={'#676767'} fontSize={14}>
                      {t('bookingNumber')}
                    </Typography>
                    <Typography fontSize={22}>{item.shipment_number}</Typography>
                  </div>
                  <div className="split"></div>

                  <div className="route-info">
                    <Typography fontWeight={300} color={'#676767'} fontSize={14}>
                      {t('route')}
                    </Typography>
                    <Typography fontSize={22} fontWeight={500}>
                      {item.shipment_from}
                      <RightArrowIcon stroke={'#1B1B1B'} />{' '}
                      {item.shipment_to?.length > 20
                        ? `${item.shipment_to?.slice(0, 20)}...`
                        : item.shipment_to}
                    </Typography>
                  </div>
                </div>

                <div className="shipment-info">
                  <div className="release-date">
                    <Typography fontWeight={300} color={'#676767'} fontSize={14}>
                      {t('releaseDate')}
                    </Typography>
                    <Typography fontSize={16}>
                      {dayjs(item.shipment_date).format('DD MMMM YYYY')}
                    </Typography>
                  </div>
                  <div className="split"></div>

                  {!!item.item_shipment_end_date && (
                    <div className="arrival-date">
                      <Typography color={'#676767'} fontSize={14}>
                        {t('eta')}
                      </Typography>
                      <Typography fontSize={16}>
                        {dayjs(item.item_shipment_end_date).format('DD MMMM YYYY')}{' '}
                      </Typography>
                    </div>
                  )}
                  <div className="shipment-status">
                    {item.shipment_status === 'done' && (
                      <>
                        <SuccessIcon />{' '}
                        <Typography fontWeight={500} fontSize={14} color={'#15C183'}>
                          {' '}
                          {t('shipmentCompleted')}{' '}
                        </Typography>
                      </>
                    )}
                    {item.shipment_status !== 'done' && (
                      <>
                        <NotCompletedIcon />
                        <Typography fontWeight={500} color={'#969696'} fontSize={14}>
                          {' '}
                          {t('shipmentInProgress')}{' '}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="company-info">
                <img src={item?.company_logo} width="120" height={60} alt="company-logo" />
              </div>
              <div className="shipment-buttons">
                <a
                  onClick={() => {
                    setSelectedShipment(item);
                  }}
                  className="btn-link"
                >
                  <DocumentsIcon /> {t('seeDocuments')}
                  <ChevronRight />
                </a>

                <div className="divider"></div>

                <a
                  href={`/shipment-detail/${item.shipment_code}`}
                  style={{
                    marginLeft: 17
                  }}
                >
                  <RightArrowIcon stroke={'#1b1b1b'} />
                </a>
              </div>
            </div>
          ))}
      </div>
      <div className="all-shipments phone">
        {!isLoading &&
          shipments?.map((item, index) => (
            <div
              className="shipment-item"
              key={index}
              style={{
                borderColor: item.shipment_status === 'done' ? '#15C183' : '#DFDFDF'
              }}
              onClick={() => {
                window.location.href = `/shipment-detail/${item.shipment_code}`;
              }}
            >
              <div
                className={`shipment-type ${item.shipment_status === 'done' ? 'completed' : ''}`}
              >
                <ShipIcon />
              </div>
              <div className="shipment-infos">
                <div
                  className="shipment-info"
                  style={{
                    borderBottom: '1px solid #dfdfdf'
                  }}
                >
                  <div className="flex">
                    <div className="no-info">
                      <Typography fontWeight={300} color={'#676767'} fontSize={13}>
                        {t('bookingNumber')}
                      </Typography>
                      <Typography fontSize={18}>{item.shipment_number}</Typography>
                    </div>
                  </div>

                  <RightArrowIcon stroke={'#1B1B1B'} />
                </div>
                <div className="shipment-info">
                  <div className="route-info">
                    <Typography fontWeight={300} color={'#676767'} fontSize={13}>
                      {t('route')}
                    </Typography>
                    <Typography fontSize={18}>
                      {item.shipment_from}
                      <RightArrowIcon stroke={'#1B1B1B'} /> {item.shipment_to}
                    </Typography>
                  </div>
                </div>

                <div className="shipment-info">
                  <div className="release-date">
                    <Typography fontWeight={300} color={'#676767'} fontSize={13}>
                      {t('releaseDate')}
                    </Typography>
                    <Typography fontSize={13}>
                      {dayjs(item.shipment_date).format('DD MMMM YYYY')}{' '}
                    </Typography>
                  </div>
                  {!!item.shipment_end_date && <div className="split"></div>}

                  {!!item.shipment_end_date && (
                    <div className="arrival-date">
                      <Typography fontWeight={300} color={'#676767'} fontSize={13}>
                        {t('eta')}
                      </Typography>
                      <Typography fontSize={13}>
                        {dayjs(item.shipment_end_date).format('DD MMMM YYYY')}
                      </Typography>
                    </div>
                  )}
                </div>

                <div className="shipment-info">
                  <div className="release-date">
                    <img src={item?.company_logo} width="100" alt="company-logo" />
                  </div>
                  <div className="split"></div>

                  <div className="doc-btn">
                    <Button
                      backgroundColor={'#FFF'}
                      borderRadius={'25px'}
                      border={'1px solid #DFDFDF'}
                      color={'#1B1B1B'}
                      hoverBackgroundColor={'#FFF'}
                      hoverColor={'#1B1B1B'}
                      onClick={() => {
                        setSelectedShipment(item);
                      }}
                    >
                      <DocumentsIcon />
                      {t('seeDocuments')}
                      <ChevronRight />
                    </Button>

                    <div className="divider"></div>
                  </div>
                </div>
                <div className="shipment-status">
                  {item.status === 1 && (
                    <>
                      <SuccessIcon />{' '}
                      <Typography fontWeight={500} color={'#15C183'} fontSize={12}>
                        {' '}
                        {t('shipmentCompleted')}{' '}
                      </Typography>
                    </>
                  )}
                  {item.status !== 1 && (
                    <>
                      <NotCompletedIcon />
                      <Typography color={'#969696'} fontWeight={500} fontSize={12}>
                        {' '}
                        {t('shipmentInProgress')}{' '}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      {!isLoading && shipments?.length === 0 && (
        <Typography textAlign={'center'} fontSize={20} color={'#D71A21'}>
          {t('cantFindShipment')} 🔴
        </Typography>
      )}
      {!isLoading && shipments?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={pagination?.total}
          onPageChange={handlePageChange}
          hasMore={pagination?.hasMore}
        />
      )}

      <ShipmentDocumentModal
        selectedShipment={selectedShipment}
        setSelectedShipment={setSelectedShipment}
      />
    </S.ShipmentsWrap>
  );
}

export { Shipments };
