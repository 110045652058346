import React from 'react';

function RightArrowIcon({ stroke }) {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.1543 0.999985L14.4999 7.34563L8.1543 13.6913"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4999 7.34564L1 7.34564"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { RightArrowIcon };
