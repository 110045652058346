import React from 'react';

import * as S from './style';

const Typography = ({
  children,
  fontSize,
  fontWeight,
  color,
  textAlign,
  margin,
  padding,
  lineHeight,
  letterSpacing,
  style,
  phoneFontSize,
  inline
}) => {
  return (
    <S.StyledTypography
      inline={inline}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      textAlign={textAlign}
      margin={margin}
      padding={padding}
      style={style}
      phoneFontSize={phoneFontSize}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
    >
      {children}
    </S.StyledTypography>
  );
};

export { Typography };
