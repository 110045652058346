import React from 'react';

import * as S from './style';
function Modal({ isOpen, onClose, children, width = 300, isLoading = false }) {
  if (!isOpen) return null;

  return (
    <S.ModalBackground onClick={onClose}>
      <S.ModalContainer width={width} onClick={e => e.stopPropagation()}>
        {!isLoading && (
          <S.CloseButton onClick={onClose}>
            <span>&times;</span>
          </S.CloseButton>
        )}
        <S.ModalContent>{children}</S.ModalContent>
      </S.ModalContainer>
    </S.ModalBackground>
  );
}

export { Modal };
