import React, { useEffect } from 'react';

const CountdownTimer = ({ timeLeft, setTimeLeft }) => {
  const radius = 13.5; // Küçük daire için yarıçap 9 (çünkü 20px boyut olacak)
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  // StrokeDashoffset ayarı, zamanı kalan kısmı göstermek için
  const strokeDashoffset = circumference - (timeLeft / 60) * circumference;

  return (
    <div
      style={{
        position: 'absolute', // Div'i absolute yapıyoruz
        width: '30px',
        height: '30px',
        top: '50%', // Örnek konumlandırma, istediğiniz yere göre değiştirebilirsiniz
        right: '10px',
        transform: 'translateY(-50%)'
      }}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        style={{
          position: 'absolute',
          left: 0
        }}
      >
        <circle cx="15" cy="15" r={radius} fill="none" stroke="lightgray" strokeWidth="2" />
        <circle
          cx="15"
          cy="15"
          r={radius}
          fill="none"
          stroke="#15C183"
          strokeWidth="2"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          transform="rotate(-90 15 15)" // Saat yönünde animasyon
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '14px',
          color: '#15C183',
          fontWeight: '500'
        }}
      >
        {timeLeft}
      </div>
    </div>
  );
};

export { CountdownTimer };
