import React from 'react';

function HearthIcon(props) {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0652 0.700195C11.9886 0.700195 11.1821 1.06188 10.5954 1.51151C9.98687 1.97791 8.53608 1.97781 7.92752 1.51142C7.34089 1.06184 6.53436 0.700195 5.45759 0.700195C2.61334 0.700195 0.899902 3.41183 0.899902 5.64652C0.899902 8.26067 4.97384 11.8315 7.43437 13.7497C8.51663 14.5934 10.0062 14.5936 11.0886 13.7499C13.5492 11.8321 17.6229 8.26209 17.6229 5.64719C17.6229 3.41183 15.9108 0.700195 13.0652 0.700195Z"
        stroke={props.stroke ?? '#1B1B1B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { HearthIcon };
