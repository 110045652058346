import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Typography } from '@/components';
import { ChevronRight, DocumentsIcon } from '@/components/Icons';
import { HearthIcon } from '@/components/Icons/HearthIcon';
import { ShareIcon } from '@/components/Icons/ShareIcon';
import { ShipIcon } from '@/components/Icons/ShipIcon';
import { Loader } from '@/components/Loader';
import { ShipmentDocumentModal } from '@/components/ShipmentDocumentModal';

import ShipmentService from '../../service/shipments';

import * as S from './style';

const getContainerIcon = name => {
  switch (name) {
    case '20-DC':
      return '/images/ContainerIcons/20-DC.svg';
    case '40-DC':
      return '/images/ContainerIcons/40-DC.svg';
    case '40-HC':
      return '/images/ContainerIcons/40-HC.svg';
    case '45-HC':
      return '/images/ContainerIcons/45-HC.svg';
    case '20-DC-RC':
      return '/images/ContainerIcons/20-DC-RC.svg';
    case '40-HC-RC':
      return '/images/ContainerIcons/40-HC-RC.svg';
    case '20-FR':
      return '/images/ContainerIcons/20-FR.svg';
    case '40-FR':
      return '/images/ContainerIcons/40-FR.svg';
    case '40-COLLAPSIBLE-FR':
      return '/images/ContainerIcons/40-Collapsable-FR.svg';
    case '40-HIGH-COLLAPSIBLE-FR':
      return '/images/ContainerIcons/40-High-Collapsable-FR.svg';
    case '20-OT':
      return '/images/ContainerIcons/20-OT.svg';
    case '40-OT':
      return '/images/ContainerIcons/40-OT.svg';
    default:
      return '/images/ContainerIcons/20-DC.svg';
  }
};
function ShipmentDetail() {
  // const [showDropdown, setShowDropdown] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const { t } = useTranslation();

  const [shipmentDetail, setShipmentDetail] = useState({});
  const params = useParams();
  const getShipmentDetail = async () => {
    setIsLoading(true);
    try {
      const res = await ShipmentService.getShipmentDetail(params.id);
      if (res.status === 200) {
        setShipmentDetail(res.data);
        setIsFavorited(res.data.is_favorited);
      }
    } catch (error) {
      window.location.href = '/shipments';
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getShipmentDetail();
  }, []);

  const renderEvents = (isMobile = false) => {
    const items = [
      {
        id: 1,
        location: '',
        altText: t('empty_equipment_purchase_date'),
        icon: (
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7.5L11 7.5M11 7.5L7.66667 4M11 7.5L7.66667 11"
              stroke="#DFDFDF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.5 1V14" stroke="#DFDFDF" strokeLinecap="round" />
          </svg>
        ),
        description: shipmentDetail?.YukRezvasyonTarih
          ? dayjs(shipmentDetail?.YukRezvasyonTarih).format('DD MMMM YYYY')
          : ''
      }
    ];

    if (shipmentDetail?.TesAlinanYer) {
      items.push({
        id: 2,
        location: shipmentDetail?.TesAlinanYer,
        altText: t('loading_date'),
        icon: (
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_510_20999" fill="white">
              <rect y="6.66211" width="14.2123" height="9.23799" rx="0.5" />
            </mask>
            <rect
              y="6.66211"
              width="14.2123"
              height="9.23799"
              rx="0.5"
              stroke="white"
              strokeWidth="2"
              mask="url(#path-1-inside-1_510_20999)"
            />
            <path
              d="M3.55322 13.0576L3.55322 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.10596 13.0576L7.10596 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6592 13.0576L10.6592 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M7.10596 5.32947V3.99707" stroke="white" />
            <path
              d="M7.10633 0V1.3324C6.66219 1.3324 5.77393 1.59888 5.77393 2.6648C5.77393 3.10894 6.04041 3.99721 7.10633 3.99721C7.55046 3.99721 8.43873 3.73073 8.43873 2.6648"
              stroke="white"
              strokeWidth="0.8"
            />
            <path
              d="M3.99707 7.10641L7.10601 4.88574L10.2149 7.10641"
              stroke="white"
              strokeWidth="0.8"
            />
          </svg>
        ),
        description: shipmentDetail?.YuklemeTarih
          ? dayjs(shipmentDetail?.YuklemeTarih).format('DD MMMM YYYY')
          : ''
      });
    }

    if (shipmentDetail?.shipment_date) {
      items.push({
        id: 3,
        location: shipmentDetail?.shipment_from,
        altText: t('ship_departure'),
        icon: <ShipIcon width={18} />,
        description: shipmentDetail?.shipment_date
          ? dayjs(shipmentDetail?.shipment_date).format('DD MMMM YYYY')
          : ''
      });
    }

    if (shipmentDetail?.AktamaTarihi1) {
      items.push({
        id: 4,
        location1: t('transfer_port_1'),
        location: `${shipmentDetail?.AktarmaLiman1}`,
        icon: (
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4L13 4M13 4L10 1M13 4L10 7M13 12H1M1 12L4 9M1 12L4 15"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        altText: shipmentDetail?.AktarmaGemi1
          ? `${t('ship')}: ${shipmentDetail?.AktarmaGemi1}`
          : '',
        description: shipmentDetail?.AktamaTarihi1
          ? dayjs(shipmentDetail?.AktamaTarihi1).format('DD MMMM YYYY')
          : ''
      });
    }

    if (shipmentDetail?.AktamaTarihi2) {
      items.push({
        id: 4,
        location1: t('transfer_port_2'),
        location: `${shipmentDetail?.AktarmaLiman2}`,
        icon: (
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4L13 4M13 4L10 1M13 4L10 7M13 12H1M1 12L4 9M1 12L4 15"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        altText: shipmentDetail?.AktarmaGemi2
          ? `${t('ship')}: ${shipmentDetail?.AktarmaGemi2}`
          : '',
        description: shipmentDetail?.AktamaTarihi2
          ? dayjs(shipmentDetail?.AktamaTarihi2).format('DD MMMM YYYY')
          : ''
      });
    }
    if (shipmentDetail?.AktamaTarihi3) {
      items.push({
        id: 4,
        location1: t('transfer_port_3'),
        location: `${shipmentDetail?.AktarmaLiman3}`,
        icon: (
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4L13 4M13 4L10 1M13 4L10 7M13 12H1M1 12L4 9M1 12L4 15"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        altText: shipmentDetail?.AktarmaGemi3
          ? `${t('ship')}: ${shipmentDetail?.AktarmaGemi3}`
          : '',
        description: shipmentDetail?.AktamaTarihi3
          ? dayjs(shipmentDetail?.AktamaTarihi3).format('DD MMMM YYYY')
          : ''
      });
    }

    if (shipmentDetail?.shipment_end_date) {
      items.push({
        id: 6,
        location: shipmentDetail?.shipment_to,
        altText: t('ship_arrival'),
        icon: <ShipIcon width={18} />,
        description: shipmentDetail?.shipment_end_date
          ? dayjs(shipmentDetail?.shipment_end_date).format('DD MMMM YYYY')
          : ''
      });
    }

    if (shipmentDetail?.TesEdilenYer) {
      items.push({
        id: 7,
        location: shipmentDetail?.TesEdilenYer,
        icon: (
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_510_20999" fill="white">
              <rect y="6.66211" width="14.2123" height="9.23799" rx="0.5" />
            </mask>
            <rect
              y="6.66211"
              width="14.2123"
              height="9.23799"
              rx="0.5"
              stroke="white"
              strokeWidth="2"
              mask="url(#path-1-inside-1_510_20999)"
            />
            <path
              d="M3.55322 13.0576L3.55322 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.10596 13.0576L7.10596 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6592 13.0576L10.6592 9.50454"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M7.10596 5.32947V3.99707" stroke="white" />
            <path
              d="M7.10633 0V1.3324C6.66219 1.3324 5.77393 1.59888 5.77393 2.6648C5.77393 3.10894 6.04041 3.99721 7.10633 3.99721C7.55046 3.99721 8.43873 3.73073 8.43873 2.6648"
              stroke="white"
              strokeWidth="0.8"
            />
            <path
              d="M3.99707 7.10641L7.10601 4.88574L10.2149 7.10641"
              stroke="white"
              strokeWidth="0.8"
            />
          </svg>
        ),
        altText: t('final_delivery'),
        description: ''
      });
    }

    return isMobile ? (
      items?.map(item => {
        return (
          <div className="event event-mobile" key={item.id}>
            <div className="details">
              <div className="location">
                {!!item.location1 && (
                  <Typography fontWeight={700} fontSize={12} textAlign={'left'}>
                    {/* Show the first two words */}
                    {item.location1}
                  </Typography>
                )}
                <Typography fontWeight={700} fontSize={15} textAlign={'left'}>
                  {/* Show the first two words */}
                  {item.location}
                </Typography>
              </div>
              <div className="details-right">
                <div
                  className="circle"
                  style={{
                    margin: '0 auto'
                  }}
                >
                  {item?.icon}
                </div>
                <div className="detail-text-wrapper">
                  <div className="detail-text">
                    <Typography fontSize={15} fontWeight={500} textAlign={'left'}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.altText
                        }}
                      />
                    </Typography>
                  </div>
                  <div className="detail-text">
                    <Typography fontSize={12} fontWeight={500} color={'#676767'} textAlign={'left'}>
                      {item?.description}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <>
        {items?.map(item => (
          <div className="event event-web" key={item.id}>
            <div
              className="details"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100px'
              }}
            >
              {!!item.location1 && (
                <div
                  className="location"
                  style={{
                    position: 'absolute',
                    top: -20,
                    width: '100%',
                    left: '0%'
                  }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    textAlign={'center'}
                    style={{
                      minWidth: '100px'
                    }}
                  >
                    {item.location1}
                  </Typography>
                </div>
              )}
              <div className="location">
                <Typography fontWeight={700} fontSize={16} style={{}} textAlign={'center'}>
                  {item.location === 'null' ||
                  !item.location ||
                  item.location === undefined ||
                  item.location === null
                    ? ''
                    : item.location}
                </Typography>
              </div>
              <div
                className="circle"
                style={{
                  margin: '0 auto',
                  marginTop: item?.location ? '0' : '24px'
                }}
              >
                {item?.icon}
              </div>

              <div>
                <Typography fontSize={14} fontWeight={500} textAlign={'center'}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item?.altText
                    }}
                  />
                </Typography>
              </div>
              <div>
                <Typography color={'#676767'} fontSize={12} fontWeight={500} textAlign={'center'}>
                  {item?.description}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <S.ShipmentDetailWrap>
      <Typography fontSize={42}>{t('shipment_and_container_tracking')}</Typography>
      <div className="shipment-item web">
        <div
          className={`shipment-type ${
            shipmentDetail.shipment_status === 'done' ? 'completed' : ' '
          }`}
        >
          <ShipIcon />
        </div>
        <div className="shipment-infos">
          <div className="shipment-info">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={16} fontWeight={700}>
                {t('bookingNumber')}
              </Typography>
              <Typography fontSize={22} fontWeight={275}>
                {shipmentDetail.shipment_number}
              </Typography>
            </div>
          </div>
          <div className="shipment-info">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={16} fontWeight={700}>
                {t('start')}
              </Typography>
              <Typography fontSize={22} fontWeight={275}>
                {shipmentDetail?.shipment_from}
              </Typography>
            </div>
          </div>
          <div className="shipment-info">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={16} fontWeight={700}>
                {t('arrival')}
              </Typography>
              <Typography fontSize={22} fontWeight={275}>
                {shipmentDetail?.shipment_to}
              </Typography>
            </div>
          </div>
          <div className="shipment-info">
            <div className="no-info">
              <Typography fontSize={22} fontWeight={300}>
                <img
                  style={{
                    marginTop: 9
                  }}
                  src={shipmentDetail?.company_logo}
                  alt="logo"
                  width={100}
                />
              </Typography>
            </div>
          </div>
        </div>

        <div className="shipment-buttons">
          <Button
            backgroundColor={'#FFF'}
            borderRadius={'25px'}
            border={'1px solid #DFDFDF'}
            color={'#1B1B1B'}
            height={'max-content'}
            hoverBackgroundColor={'#FFF'}
            hoverColor={'#1B1B1B'}
            padding={' 10px 10px;'}
            fontSize={'12px'}
            fontWeight={'500'}
            onClick={() => setSelectedShipment(shipmentDetail)}
          >
            <DocumentsIcon />
            {t('seeDocuments')}
            <ChevronRight />
          </Button>

          <Button
            backgroundColor={'#FFF'}
            borderRadius={'50px'}
            border={'1px solid #DFDFDF'}
            color={'#1B1B1B'}
            height={'max-content'}
            hoverBackgroundColor={'#FFF'}
            fontSize={'12px'}
            fontWeight={'500'}
            padding={' 12px 10px'}
            hoverColor={'#1B1B1B'}
            onClick={() => {
              if (isFavorited) {
                ShipmentService.deleteFavorite(shipmentDetail.id);
              } else {
                ShipmentService.addFavorite(shipmentDetail.id);
              }

              setIsFavorited(!isFavorited);
            }}
          >
            <HearthIcon
              fill={isFavorited ? '#15c183' : 'transparent'}
              stroke={isFavorited ? '#15c183' : 'black'}
            />
          </Button>
          <Button
            backgroundColor={'#FFF'}
            borderRadius={'50px'}
            border={'1px solid #DFDFDF'}
            color={'#1B1B1B'}
            height={'max-content'}
            hoverBackgroundColor={'#FFF'}
            fontSize={'12px'}
            fontWeight={'500'}
            padding={' 12px 10px'}
            hoverColor={'#1B1B1B'}
            onClick={() => {
              window.open(
                `https://api.orbislog.com/share/${shipmentDetail.shipment_uuid}`,
                '_blank'
              );
            }}
          >
            <ShareIcon />
          </Button>
        </div>
        {/* <div
          className="shipment-show-detail"
          style={{
            cursor: ' pointer'
          }}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <Typography fontWeight={500} fontSize={15}>
            {showDropdown ? t('hide') : t('details')}
          </Typography>
          {showDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div> */}
      </div>
      <div className="shipment-item phone">
        <div
          className={`shipment-type 
                ${shipmentDetail.shipment_status === 'done' ? 'completed' : ' '}`}
        >
          <ShipIcon />
        </div>
        <div className="shipment-infos">
          <div className="shipment-info">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={14} fontWeight={700}>
                {t('bookingNumber')}
              </Typography>
              <Typography fontSize={18} fontWeight={300}>
                {shipmentDetail.shipment_number}
              </Typography>
            </div>
          </div>
          <div className="shipment-favorite-phone">
            <div
              className="no-info"
              style={{
                display: 'flex',
                gap: 8
              }}
            >
              <Button
                backgroundColor={'#FFF'}
                borderRadius={'50px'}
                border={'1px solid #DFDFDF'}
                color={'#1B1B1B'}
                height={'max-content'}
                hoverBackgroundColor={'#FFF'}
                fontSize={'12px'}
                fontWeight={'500'}
                padding={' 12px 10px'}
                hoverColor={'#1B1B1B'}
                onClick={() => {
                  if (isFavorited) {
                    ShipmentService.deleteFavorite(shipmentDetail.id);
                  } else {
                    ShipmentService.addFavorite(shipmentDetail.id);
                  }

                  setIsFavorited(!isFavorited);
                }}
              >
                <HearthIcon
                  fill={isFavorited ? '#15c183' : 'transparent'}
                  stroke={isFavorited ? '#15c183' : 'black'}
                />
              </Button>
              <Button
                backgroundColor={'#FFF'}
                borderRadius={'50px'}
                border={'1px solid #DFDFDF'}
                color={'#1B1B1B'}
                height={'max-content'}
                hoverBackgroundColor={'#FFF'}
                fontSize={'12px'}
                fontWeight={'500'}
                padding={' 12px 10px'}
                hoverColor={'#1B1B1B'}
                onClick={() => {
                  try {
                    navigator.share({
                      title: 'Orbis Route',
                      text: 'Sevkiyat',
                      url: `https://api.orbislog.com/share/${shipmentDetail.shipment_uuid}`
                    });
                  } catch {
                    window.open(
                      `https://api.orbislog.com/share/${shipmentDetail.shipment_uuid}`,
                      '_blank'
                    );
                  }
                }}
              >
                <ShareIcon />
              </Button>
            </div>
          </div>
          <div className="shipment-info start-arrival">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={14} fontWeight={700}>
                {t('start')}
              </Typography>
              <Typography fontSize={18} fontWeight={300}>
                {shipmentDetail?.shipment_from}
              </Typography>
            </div>
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={14} fontWeight={700}>
                {t('arrival')}
              </Typography>
              <Typography fontSize={18} fontWeight={300}>
                {shipmentDetail?.shipment_to}
              </Typography>
            </div>
          </div>
          <div className="shipment-info">
            <div className="no-info">
              <Typography color={'#1B1B1B'} fontSize={14} fontWeight={700}>
                {t('company')}
              </Typography>
              <Typography fontSize={18} fontWeight={300}>
                <img src={shipmentDetail?.company_logo} alt="logo" width={80} />
              </Typography>
            </div>
          </div>
          <div className="shipment-buttons">
            <div className="download-buttons"></div>
            <Button
              backgroundColor={'#FFF'}
              borderRadius={'25px'}
              border={'1px solid #DFDFDF'}
              color={'#1B1B1B'}
              height={'max-content'}
              hoverBackgroundColor={'#FFF'}
              hoverColor={'#1B1B1B'}
              padding={' 10px 10px;'}
              fontSize={'11px'}
              fontWeight={'500'}
            >
              <DocumentsIcon />
              {t('seeDocuments')}
              <ChevronRight stroke={'#000'} />
            </Button>
          </div>
          {/* <div
            className="shipment-show-detail"
            style={{
              cursor: ' pointer'
            }}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <Typography fontWeight={500} fontSize={15}>
              {showDropdown ? t('hide') : t('details')}
            </Typography>
            {showDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div> */}
        </div>
      </div>
      <div className={`shipment-dropdown-detail visible `}>
        {/* <div className={`shipment-dropdown-detail visible ${showDropdown && 'visible'}`}> */}
        <div className="dropdown-header">
          <div
            className="item final-destination"
            style={
              {
                // paddingLeft: 50,
                // borderLeft: '1px solid  #dfdfdf'
              }
            }
          >
            <div className="item-title ">
              <Typography fontWeight={700} fontSize={16}>
                {t('final_destination')}
              </Typography>
            </div>
            <Typography fontWeight={500} fontSize={15} textAlign={'right'}>
              {shipmentDetail?.TesEdilenYer?.length > 0
                ? shipmentDetail?.TesEdilenYer
                : shipmentDetail?.shipment_to}
              {(shipmentDetail?.TesEdilenYer?.length > 0 || !!shipmentDetail?.shipment_to) && ' / '}
              {shipmentDetail?.shipment_end_date && (
                <>
                  {shipmentDetail?.shipment_end_date
                    ? dayjs(shipmentDetail?.shipment_end_date).format('DD MMMM YYYY')
                    : '-'}
                </>
              )}
            </Typography>
          </div>

          {/* {!!shipmentDetail?.Gemi && ( */}
          <div className="item" style={{}}>
            <div className="item-title">
              <Typography fontWeight={700} fontSize={16}>
                {t('shipName')}
              </Typography>
            </div>
            <Typography fontWeight={500} fontSize={15} textAlign={'left'}>
              {shipmentDetail.Gemi || '-'}
            </Typography>
          </div>
          {/* )} */}
          {/* {!!shipmentDetail?.GemiSeferNo && ( */}
          <div className="item" style={{}}>
            <div className="item-title">
              <Typography fontWeight={700} fontSize={16}>
                {t('shipVoyageNo')}
              </Typography>
            </div>
            <Typography fontWeight={500} fontSize={15} textAlign={'left'}>
              {shipmentDetail.GemiSeferNo || '-'}
            </Typography>
          </div>
          {/* )} */}
          {!!shipmentDetail?.HBLNo && (
            <div
              className="item hbl-no"
              style={
                {
                  // marginLeft: 20,
                  // paddingLeft: 20,
                  // borderLeft: '1px solid  #dfdfdf'
                }
              }
            >
              <div className="item-title">
                <Typography fontWeight={700} fontSize={16}>
                  HBL NO
                </Typography>
              </div>
              <Typography fontWeight={500} fontSize={15} textAlign={'right'}>
                {shipmentDetail.HBLNo || '-'}
              </Typography>
            </div>
          )}
          {!!shipmentDetail?.MBLNo && (
            <div className="item mbl-no" style={{}}>
              <div className="item-title">
                <Typography fontWeight={700} fontSize={16}>
                  MBL NO
                </Typography>
              </div>
              <Typography fontWeight={500} fontSize={15} textAlign={'right'}>
                {shipmentDetail.MBLNo || '-'}
              </Typography>
            </div>
          )}
        </div>
        <div className="dropdown-note">
          {/* <Typography fontWeight={500} fontSize={14}>
            {t('  ')}
          </Typography> */}
        </div>
        <div className="timeline-container">
          <div className="timeline">{renderEvents()}</div>
          <div className="timeline mobile">{renderEvents(true)}</div>
        </div>
      </div>
      <div className="container-list">
        {shipmentDetail.containers?.map((item, index) => (
          <div className="container-item" key={index}>
            <div className="container-header">
              <div className="container-header-left">
                <img
                  src={getContainerIcon(item?.KonteynerTip)}
                  alt="container"
                  style={{
                    marginRight: -5
                  }}
                />
              </div>
              <div className="container-header-right"></div>
            </div>
            <div className="container-wrapper">
              <div className="container-details">
                <div className="container-detail-item item-first">
                  <img src={getContainerIcon(item?.KonteynerTip)} alt="container" />
                </div>

                {!!item?.KonteynerTip && (
                  <div className="container-detail-item hide-mobile">
                    <Typography fontSize={14} fontWeight={1000}>
                      {item.KonteynerNo}
                    </Typography>

                    <Typography fontSize={14} fontWeight={500}>
                      {item?.KonteynerTip}
                    </Typography>
                  </div>
                )}

                <div className="container-detail-item">
                  <Typography fontSize={14} fontWeight={1000}>
                    {t('gross_kg')}
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {Number(item?.Brutkg ?? 0).toFixed()}
                  </Typography>
                </div>
                <hr className="container-hr" />

                <div className="container-detail-item">
                  <Typography fontSize={14} fontWeight={1000}>
                    {t('quantity')}
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {Number(item?.Adet ?? 0).toFixed()}
                  </Typography>
                </div>
                <div className="container-detail-item">
                  <Typography fontSize={14} fontWeight={1000}>
                    {t('packaging_type')}
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {item.AmbalajCinsi}
                  </Typography>
                </div>
                {!item?.KonteynerTeslimTarih && <hr className="container-hr" />}

                <div className="container-detail-item">
                  <Typography fontSize={14} fontWeight={1000}>
                    Vgm
                  </Typography>

                  <Typography fontSize={14} fontWeight={500}>
                    {Number(item?.VGMKG) === 1 ? t('done') : t('not_done')}
                  </Typography>
                </div>
                {!!item?.KonteynerTeslimTarih && (
                  <>
                    <hr className="container-hr" />
                    <div className="container-detail-item">
                      <Typography fontSize={14} fontWeight={1000}>
                        {t('container_delivery_date')}
                      </Typography>

                      <Typography fontSize={14} fontWeight={500}>
                        {dayjs(item?.KonteynerTeslimTarih).format('DD MMMM YYYY')}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ShipmentDocumentModal
        selectedShipment={selectedShipment}
        setSelectedShipment={setSelectedShipment}
      />
    </S.ShipmentDetailWrap>
  );
}

export { ShipmentDetail };
