import React from 'react';

import * as S from './style';
function Loader() {
  return (
    <S.loader>
      <div className="loader-container">
        <img src={'/images/route_loader.gif'} alt="Logo" className="loader-logo" />
      </div>
    </S.loader>
  );
}

export { Loader };
