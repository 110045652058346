import React from 'react';

function UserIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.57251 16.2672C5.22317 13.88 7.40663 12.125 10 12.125C12.5934 12.125 14.7769 13.88 15.4275 16.2672M12.625 7.25C12.625 8.69975 11.4498 9.875 10 9.875C8.55027 9.875 7.37501 8.69975 7.37501 7.25C7.37501 5.80025 8.55027 4.625 10 4.625C11.4498 4.625 12.625 5.80025 12.625 7.25Z"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="10" r="9.25" stroke="#1B1B1B" strokeWidth="1.5" />
    </svg>
  );
}

export { UserIcon };
