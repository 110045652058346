import styled from 'styled-components';
import { media } from '../../style/helper';

export const FooterWrapper = styled.div`
  border-top: 1px solid #dfdfdf;
  margin-bottom: 12px;
  .footer-first-container {
    display: flex;
    justify-content: space-between;
  }
  margin-top: 48px;
  padding: 0px 132px;
  ul {
    align-items: center;
    display: flex;
    gap: 35px;
    height: 100%;
    li {
      font-weight: 500;
      position: relative;
      a {
        color: #676767;
        margin-left: 15px;
        position: relative;
        &:after {
          content: '';
          width: 2px;
          height: 100%;
          position: absolute;
          right: -10px;
          display: block;
          background: #dfdfdf;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  ${media.phone`
    padding:10px 10px;
    ul{
    gap:22px;
    a{
      font-size:12px;
    }
    }
  
  `}
`;

// export {  };
