import React from 'react';

function VerificationIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.91667 9.66667V6.125C6.91667 4.39911 8.31578 3 10.0417 3C11.7676 3 13.1667 4.39911 13.1667 6.125V9.66667M5.66667 18H14.4167C15.3371 18 16.0833 17.2538 16.0833 16.3333V11.3333C16.0833 10.4129 15.3371 9.66667 14.4167 9.66667H5.66667C4.74619 9.66667 4 10.4129 4 11.3333V16.3333C4 17.2538 4.74619 18 5.66667 18ZM11.9167 13.8333C11.9167 14.7538 11.1705 15.5 10.25 15.5C9.32952 15.5 8.58333 14.7538 8.58333 13.8333C8.58333 12.9129 9.32952 12.1667 10.25 12.1667C11.1705 12.1667 11.9167 12.9129 11.9167 13.8333Z"
        stroke="#969696"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { VerificationIcon };
