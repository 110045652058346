import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import UserService from '../../service/user';
import { Button } from '../Button';
import { EmailIcon, NotificationsIcon, UserIcon } from '../Icons';
import { BirthDayIcon } from '../Icons/BirthdayIcon';
import { CloseIcon } from '../Icons/CloseIcon';
import { EditIcon } from '../Icons/EditIcon';
import { PhoneIcon } from '../Icons/PhoneIcon';
import { ShipIcon } from '../Icons/ShipIcon';
import { SignOutIcon } from '../Icons/SignOutIcon';
import LanguageDropdown from '../LanguageDropdown';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';

import * as S from './style';

function Header() {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileEditActive, setIsProfileEditActive] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [userName, setUserName] = useState(user?.name);
  const [userSurname, setUserSurname] = useState(user?.surname);
  const [userEmail, setUserEmail] = useState(user?.email);
  const [userPhone, setUserPhone] = useState(user?.phoneNumber);
  const [userBirthDay, setUserBirthDay] = useState(user?.birthday);
  const [updateErrorText, setUpdateErrorText] = useState('');

  const toggleDropdown = () => {
    if (userNotifications?.totalCount > 0) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const getNotifications = async () => {
    try {
      const req = await UserService.getUserNotifications();
      setUserNotifications(req.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <S.HeaderWrapper>
      <div className="header-logo">
        <a href="/">
          <img src="/images/orbis-logo.svg" height={'100%'} alt="orbis logo" />
        </a>
      </div>
      <div className="header-navbar">
        <ul className="web-menu">
          <li className={window.location.pathname === '/' ? 'active' : ''}>
            <a href="/">{t('home')}</a>
          </li>
          <li className={window.location.pathname === '/shipments' ? 'active' : ''}>
            <a href="/shipments">{t('shipments')}</a>
          </li>
          <li className={window.location.pathname === '/documents' ? 'active' : ''}>
            <a href="/documents">{t('documents')}</a>
          </li>
          <li>
            <a href="#" onClick={toggleDropdown}>
              <S.NotificationsBadge className="badge">
                {userNotifications?.totalCount}
              </S.NotificationsBadge>
              <NotificationsIcon />
            </a>
          </li>
          <li className="nav-user">
            <span className="welcome-user">
              {t('greeting')}
              <Typography fontWeight={'bold'} textAlign={'right'}>
                {user?.name}
              </Typography>
            </span>
            <a href="#" onClick={togglePanel}>
              <UserIcon />
            </a>
          </li>
          <li>
            <LanguageDropdown hasText />
          </li>
        </ul>
        <div className="mobile-navbar">
          <div
            style={{
              position: 'relative'
            }}
          >
            <a href="#" onClick={toggleDropdown}>
              <S.NotificationsBadge className="badge">
                {userNotifications?.totalCount}
              </S.NotificationsBadge>
              <NotificationsIcon />
            </a>
          </div>
          <div>
            <a href="#" onClick={togglePanel}>
              <UserIcon />
            </a>
          </div>
          <S.HamburgerMenu onClick={toggleMobileMenu}>
            <div />
            <div />
            <div />
          </S.HamburgerMenu>
        </div>
        {isDropdownOpen && (
          <S.Dropdown>
            <div className="notifications-header">
              <Typography fontWeight={400}>
                {t('notifications')} ({userNotifications?.totalCount})
              </Typography>
              <div
                onClick={() => closeDropdown()}
                style={{
                  cursor: 'pointer'
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="notifications">
              {userNotifications?.notifications.map((notification, index) => (
                <a
                  href={`/shipment-detail/${notification.shipment_id}`}
                  className="notification"
                  key={index}
                >
                  <div className={`noti-type`}>
                    <ShipIcon />
                  </div>
                  <div className="noti-no">
                    <Typography fontSize={12}>{t('consignmentNumber')}</Typography>
                    <Typography fontSize={16} fontWeight={400}>
                      {notification.shipment_id}
                    </Typography>
                  </div>
                  <div className="noti-message">
                    <span>
                      <NotificationsIcon width={10} height={12} />
                      <Typography fontSize={12} fontWeight={400}>
                        {notification.content}
                      </Typography>
                    </span>
                  </div>
                </a>
              ))}
            </div>
          </S.Dropdown>
        )}
        <S.SidePanel isOpen={isPanelOpen}>
          <div className="panel-header">
            <span onClick={closePanel}>
              <CloseIcon />
            </span>
          </div>
          <div className="panel-user-info">
            <div className="user-info">
              <UserIcon />
              <div
                style={{
                  width: '100%'
                }}
              >
                <Typography color={'#676767'} fontSize={14} fontWeight={'400'}>
                  {t('name')}
                </Typography>
                {!isProfileEditActive && (
                  <Typography fontSize={16} fontWeight={600}>
                    {user?.name} {user?.surname}
                  </Typography>
                )}
                {isProfileEditActive && (
                  <div>
                    <div className="edit-profile-form" style={{ display: 'flex', gap: '10px' }}>
                      <TextInput
                        value={userName}
                        width={'100%'}
                        onChange={e => setUserName(e.target.value)}
                      />
                      <TextInput
                        value={userSurname}
                        width={'100%'}
                        onChange={e => setUserSurname(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="user-info">
              <EmailIcon stroke={'#1B1B1B'} />
              <div
                style={{
                  width: '100%'
                }}
              >
                <Typography color={'#676767'} fontSize={14} fontWeight={'400'}>
                  {t('email')}
                </Typography>
                {!isProfileEditActive && (
                  <Typography fontSize={16} fontWeight={600}>
                    {user?.email}
                  </Typography>
                )}
                {isProfileEditActive && (
                  <div className="edit-profile-form">
                    <TextInput
                      value={userEmail}
                      width={'100%'}
                      onChange={e => setUserEmail(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="user-info">
              <BirthDayIcon stroke={'#1B1B1B'} />
              <div
                style={{
                  width: '100%'
                }}
              >
                <Typography color={'#676767'} fontSize={14} fontWeight={'400'}>
                  {t('birthday')}
                </Typography>
                {!isProfileEditActive && !!userBirthDay && (
                  <Typography fontSize={16} fontWeight={600}>
                    {dayjs(user?.birthday).format('DD.MM.YYYY')}
                  </Typography>
                )}
                {isProfileEditActive && (
                  <div className="edit-profile-form">
                    <TextInput
                      inputProps={{ style: { width: '100%' } }}
                      value={userBirthDay}
                      type={'date'}
                      width={'100%'}
                      onChange={birthDay => {
                        setUserBirthDay(birthDay.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="user-info">
              <PhoneIcon stroke={'#1B1B1B'} />
              <div
                style={{
                  width: '100%'
                }}
              >
                <Typography color={'#676767'} fontSize={14} fontWeight={'400'}>
                  {t('phoneNumber')}
                </Typography>
                {!isProfileEditActive && (
                  <Typography fontSize={16} fontWeight={600}>
                    +{user?.phoneNumber}
                  </Typography>
                )}
                {isProfileEditActive && (
                  <div className="edit-profile-form" style={{ display: 'flex', gap: '10px' }}>
                    <PhoneInput
                      inputProps={{ style: { width: '100%' } }}
                      country={'tr'}
                      value={userPhone}
                      onChange={phone => setUserPhone(phone)}
                    />
                  </div>
                )}
              </div>
            </div>

            <Typography color={'#B43E3B'} margin={'15px 0px 0px 0px'}>
              {updateErrorText}
            </Typography>
          </div>
          <div className="panel-buttons">
            {!isProfileEditActive && (
              <>
                <Button
                  backgroundColor={'#1B1B1B'}
                  color={'white'}
                  borderRadius={'25px'}
                  fontSize={'15px'}
                  fontWeight={500}
                  onClick={() => setIsProfileEditActive(true)}
                >
                  <EditIcon />
                  {t('editProfile')}
                </Button>
                <Button
                  onClick={async () => {
                    await UserService.logout();
                  }}
                  backgroundColor={'#FFFFFF'}
                  color={' 1B1B1B'}
                  borderRadius={'25px'}
                  border={'1px solid #DFDFDF'}
                  fontSize={'15px'}
                  fontWeight={500}
                >
                  <SignOutIcon />
                  {t('signOut')}
                </Button>
              </>
            )}

            {isProfileEditActive && (
              <>
                <Button
                  backgroundColor={'#1B1B1B'}
                  color={'white'}
                  borderRadius={'25px'}
                  fontSize={'15px'}
                  fontWeight={500}
                  onClick={async () => {
                    try {
                      var req = await UserService.updateUserProfile({
                        name: userName,
                        surname: userSurname,
                        birthday: userBirthDay,
                        email: userEmail,
                        phone_number: userPhone
                      });
                      if (req.status === 200) {
                        user.name = userName;
                        user.surname = userSurname;
                        user.birthday = userBirthDay;
                        user.email = userEmail;
                        user.phoneNumber = userPhone;
                        localStorage.setItem('user', JSON.stringify(user));

                        setIsProfileEditActive(false);
                        setUpdateErrorText('');
                      } else {
                        setUpdateErrorText('Bir hata oluştu');
                      }
                    } catch (err) {
                      setUpdateErrorText(err.response.data.message);
                    }
                  }}
                >
                  <EditIcon />
                  {t('save')}
                </Button>
                <Button
                  onClick={async () => {
                    setUserName(user.name);
                    setUserSurname(user.surname);
                    setUserEmail(user.email);
                    setIsProfileEditActive(false);
                  }}
                  backgroundColor={'#FFFFFF'}
                  color={' 1B1B1B'}
                  borderRadius={'25px'}
                  border={'1px solid #DFDFDF'}
                  fontSize={'15px'}
                  fontWeight={500}
                  hoverBackgroundColor={'#FFFFFF'}
                  hoverColor={'#1B1B1B'}
                >
                  <CloseIcon />
                  {t('cancel')}
                </Button>
              </>
            )}
          </div>
        </S.SidePanel>
        <S.SidePanelMenu isOpen={isMobileMenuOpen}>
          <div className="panel-header">
            <span onClick={toggleMobileMenu}>
              <CloseIcon />
            </span>
          </div>

          <ul className="mobile-menu">
            <li className={window.location.pathname === '/' ? 'active' : ''}>
              <a href="/">{t('home')}</a>
            </li>
            <li className={window.location.pathname === '/shipments' ? 'active' : ''}>
              <a href="/shipments">{t('shipments')}</a>
            </li>
            <li className={window.location.pathname === '/documents' ? 'active' : ''}>
              <a href="/documents">{t('documents')}</a>
            </li>
            <li
              style={{
                width: '100%',
                marginTop: '10px',
                paddingTop: '19px',
                borderTop: '1px solid #DFDFDF'
              }}
            >
              <LanguageDropdown hasText />
            </li>
          </ul>
        </S.SidePanelMenu>
      </div>

      {isPanelOpen && <S.Overlay onClick={closePanel} />}
      {isDropdownOpen && <S.Overlay onClick={closeDropdown} />}
    </S.HeaderWrapper>
  );
}

export { Header };
