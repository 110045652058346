import React from 'react';

import { NextIcon } from '../Icons/NextIcon';
import { PrevIcon } from '../Icons/PrevIcon';

import * as S from './style';
function Pagination({ currentPage, totalPages, onPageChange, hasMore }) {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  return (
    <S.StyledPagination>
      <div className="pagination">
        <button
          className="pagination__button"
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          <PrevIcon stroke={currentPage === 1 ? '#FFFFFF' : '#1B1B1B'} />
          <PrevIcon stroke={currentPage === 1 ? '#FFFFFF' : '#1B1B1B'} />
        </button>
        <button
          className="pagination__button"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <PrevIcon stroke={currentPage === 1 ? '#FFFFFF' : '#1B1B1B'} />
        </button>
        {pages
          .filter(page => Math.abs(page - currentPage) <= 2)
          .map(page => (
            <button
              key={page}
              className={`pagination__button ${currentPage === page ? 'active' : ''}`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          ))}
        <button
          className="pagination__button"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || !hasMore}
        >
          <NextIcon stroke={currentPage === totalPages ? '#FFFFFF' : '#1B1B1B'} />
        </button>

        <button
          className="pagination__button"
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          <NextIcon stroke={currentPage === totalPages ? '#FFFFFF' : '#1B1B1B'} />
          <NextIcon stroke={currentPage === totalPages ? '#FFFFFF' : '#1B1B1B'} />
        </button>
      </div>
    </S.StyledPagination>
  );
}

export { Pagination };
