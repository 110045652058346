import styled from 'styled-components';
import { media } from '../../../style/helper';

export const SearcHShipmentWrap = styled.div`
  margin-top: 30px;
  .search-form {
    align-items: center;
    display: flex;
    gap: 14px;
    margin-top: 10px;
    ${media.phone`
  flex-direction: column;`}
    button {
      ${media.phone`
      justify-content: center;
      padding: 15px 30px;
      width: 100%;`}
    }
  }
  ${media.phone`
      padding: 0px 20px;
      `}
`;
// export {  };
