import styled from 'styled-components';

const StyledButton = styled.button`
  align-items: center;
  background-color: ${props => (props.disabled ? '#DFDFDF' : props.backgroundColor || '#D71920')};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '5px'};
  color: ${props => props.color || 'white'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => props.fontWeight || 500};
  gap: 10px;
  height: ${props => props.height || 'auto'};
  justify-content: space-between;
  margin: ${props => props.margin || '0'};
  min-height: 40px;
  padding: ${props => props.padding || '10px 30px'};
  transition: all 0.3s ease;
  width: ${props => props.width || 'auto'};
  &:hover {
    background-color: ${props => props.hoverBackgroundColor || '#D71920'};
    color: ${props => props.hoverColor || 'white'};
  }
`;

export { StyledButton };
