import React from 'react';

function NextIcon({ stroke }) {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L6 6L1 11"
        stroke={stroke ? stroke : '#1b1b1b'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { NextIcon };
