import React from 'react';

function DownloadIcon() {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3333 5.66667C11.1924 5.66667 11.8889 6.36311 11.8889 7.22222V13.4444C11.8889 14.3036 11.1924 15 10.3333 15H2.55556C1.69645 15 1 14.3036 1 13.4444V7.22222C1 6.36311 1.69645 5.66667 2.55556 5.66667M6.44441 1L6.44453 10.3333M6.44453 10.3333L4.11111 8.10606M6.44453 10.3333L8.77778 8.10612"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { DownloadIcon };
