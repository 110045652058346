import { media } from '../../style/helper';

const { default: styled } = require('styled-components');

const HeaderWrapper = styled.div`
  .modal-title {
    font-size: 32px;
    font-weight: 200;
    margin-bottom: 16px;
  }

  .modal-desc {
    font-size: 16px;
    font-weight: 300;
  }

  .booking-modal {
    font-size: 16px;
    font-weight: 100;
  }

  .flex {
    align-items: center;
    display: flex;
    gap: 6px;
    margin-bottom: 15px;
  }

  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
`;

const DocumentsWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;

  .document-item {
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    gap: 17px;
    margin-bottom: 10px;

    ${media.phone`
      justify-content: space-between;
    `}

    .name-wrapper {
      width: 150px;

      ${media.phone`
        width: auto;
        margin-right:auto;
      `}
    }

    .date-wrapper {
      ${media.phone`
        display: none;
      `}
    }
  }

  .doc-icon {
    align-items: center;
    background-color: #dfdfdf;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
  }

  .document-name {
    font-size: 14px;
    font-weight: 300;
  }

  .download-btn {
    font-size: 12px;
    margin-left: auto;
    padding-right: 9px;

    ${media.phone`
      width:40%;
      font-size: 12px;

      margin:0;
      button{
        font-size:12px;
        width:100%;
        white-space: nowrap;
        padding:10px;
        
      }
    `}
  }
`;
export { DocumentsWrapper, HeaderWrapper };
