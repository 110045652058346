import React, { useEffect, useState } from 'react';

import { Loader } from '@/components/Loader';

import UserService from '../service/user';

function AuthLayout({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // user me fetch
    UserService.getUserProfile()
      .then(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setisAuthenticated(!user);
        if (!user) {
          window.location.href = '/sign-in';
        }
      })
      .catch(() => {
        localStorage.removeItem('user');
        window.location.href = '/sign-in';
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Loader />;
  }
  return <div>{children}</div>;
}

export { AuthLayout };
