import styled from 'styled-components';

export const ShipmentsWrap = styled.div`
  margin-top: 30px;
  .filter-shipments {
    display: flex;
    gap: 10px;
    margin-top: 21px;

    input {
      min-width: 240px;
    }
    @media (max-width: 992px) {
      flex-direction: column;
    }
    .status-filter {
      display: flex;
      gap: 10px;
    }
  }
  .all-shipments.phone {
    display: none;
  }
  .all-shipments {
    margin-top: 24px;
    .shipment-item {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      display: flex;
      margin-bottom: 20px;
      .shipment-type {
        align-items: center;
        background-color: #f08326;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        padding: 0px 15px;
        @media (max-width: 992px) {
          padding: 0px 5px;
        }
      }
      .shipment-type.completed {
        background-color: #15c183;
      }
      .shipment-infos {
        @media (max-width: 992px) {
          width: 100%;
          margin-right: 14px;
        }

        .no-info,
        .release-date {
          width: 200px;
          @media (max-width: 992px) {
            width: 50%;
            white-space: nowrap;
          }
        }

        .split {
          content: '';
          display: block;
          height: 50px;
          width: 1px;
          margin-right: 18px;
          background-color: #dfdfdf;
        }
        cursor: pointer;
        .shipment-info {
          display: flex;
          margin: 11px 16px 0px 16px;
          padding-bottom: 11px;
          width: 500px;
          @media (max-width: 992px) {
            width: auto;
            gap: 0;
            margin: 10px 0px 0px 16px;
            border-bottom: 1px solid #dfdfdf;
            justify-content: space-between;
            align-items: center;
            .arrival-date {
              white-space: nowrap;
            }

            .doc-btn button {
              width: 153px;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
              font-size: 9px;
              padding: 10px 0;
            }
          }
        }
        .route-info {
          position: relative;
          white-space: nowrap;
        }
      }
      .shipment-status {
        align-items: center;
        display: flex;
        gap: 11px;
        justify-content: center;
        margin-left: 12px;
        @media (max-width: 992px) {
          gap: 9px;
          padding: 14px;
          justify-content: flex-start;
          margin-left: 0;
        }
      }
      .company-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 32px;

        img {
          border-left: 1px solid #dfdfdf;
          border-right: 1px solid #dfdfdf;
          object-fit: contain;
          padding: 0 50px;
        }
      }
      .shipment-buttons {
        align-items: center;
        display: flex;

        .divider {
          border-left: 1px solid #dfdfdf;
          height: 60px;
          margin: 0 40px 0 30px;
        }

        .btn-link {
          align-items: center;
          display: flex;
          gap: 10px;
          font-weight: 500;
          background-color: #ffffff;
          margin: 6px 0px 0px 0px;
          padding: 5px 20px;
          border: 1px solid #dfdfdf;
          border-radius: 25px;
          font-size: 12px;
          height: 36px;
          cursor: pointer;

          padding: 0;
          width: 166px;
          justify-content: center;
        }
      }
    }
  }
  .status-filter.phone {
    display: none;
  }
  ::placeholder {
    font-size: 14px;
  }
  @media (max-width: 992px) {
    padding: 0px 20px;
    .all-shipments.web {
      display: none;
    }
    .all-shipments.phone {
      display: block;
    }
    .shipment-link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }

    .status-filter.web {
      display: none;
    }
    .status-filter.phone {
      display: block;
    }
    .filter-documents {
      flex-direction: column;
    }
    .shipments-filter {
      width: 100%;
    }
    .no-filter {
      width: 100%;
    }
    .all-documents.phone {
      display: block;
      .document-buttons-phone {
        display: flex;
      }
    }
    .all-documents.web {
      display: none;
    }
  }
`;
