import React from 'react';

function PrevIcon({ stroke }) {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11L0.999999 6L6 1"
        stroke={stroke ? stroke : '#1b1b1b'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { PrevIcon };
