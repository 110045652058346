/* eslint-disable sort-exports/sort-exports */
import { css } from 'styled-components';
export const mediaSizes = {
  giant: 1920,
  wide: 1440,
  tablet: 768,
  phone: 512
};

export const media = Object.keys(mediaSizes).reduce((accumulator, label) => {
  accumulator[label] = (...style) => css`
    @media (max-width: ${mediaSizes[label]}px) {
      ${css(...style)};
    }
  `;
  return accumulator;
}, {});
