import styled from 'styled-components';

import { media } from '../../style/helper';

const AuthWrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  .stsearch-box input {
    border: none;
  }
  ${media.phone`
  overflow: scroll;`}
  width: 100%;
`;
const ImageWrapper = styled.div`
  max-height: 100%;
  img {
    object-fit: cover;
  }
  width: 50%;
  ${media.phone`
      display:none;`}
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  width: 50%;
  ${media.phone`
      width:100%;`}

  img {
    margin-left: 25px;
  }
  .user-type-buttons {
    display: flex;
    gap: 25px;
    button {
      justify-content: center;
    }
  }
  .form-group {
    display: flex;
    gap: 25px;
  }
  .form-select-group {
    display: flex;
    margin-top: 15px;
    gap: 25px;
  }
  .form-phone {
    width: 50%;
    margin-top: 15px;
  }
  .numbers-area {
    display: flex;
    gap: 25px;
  }
  .landline-number {
    width: 100%;
    margin-top: 15px;
  }
  /* .extension-number {
    width: 35%;
  } */
  ${media.phone`
  .numbers-area{
    flex-direction:column;
    gap: 5px;
    margin-top:5px;
  }
  .extension-number,.landline-number {
    width:100%;
  }
      `}

  .submit-sign-in {
    margin-top: 32px;
  }
  .submit-sign-in.phone {
    display: none;
  }
  .forgot-button-phone {
    display: none;
  }
  ${media.phone`
      .submit-sign-in.web{
      display:none;
    }
      .submit-sign-in.phone{
      display:block;
      button{
        width: 100%;
        justify-content:center;
      }
    }
    `}
  .auth-footer {
    border-top: 1px solid #ededed;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: auto;
    padding: 20px;
    a {
      text-decoration: none;
    }
    ${media.phone`
    justify-content: center;
`}
  }
`;
const FormArea = styled.div`
  padding: 52px 132px 0px 100px;
  ${media.phone`
      padding:20px;`}
  .forgot-password {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    a {
    }
    .privacy-policy-check {
      font-weight: 600;
      a {
        text-decoration-color: #1b1b1b;
        color: #1b1b1b;
      }
    }
  }
  .submit-sign-in {
    margin-top: 32px;
  }
  .sign-up-area {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    margin-top: 60px;
    padding: 25px 62px 25px 27px;
  }
`;

const mailCodeWrapper = styled.div`
  padding: 52px 132px 0px 100px;
  .mail-code-actions {
    display: flex;
    gap: 16px;
  }
`;

export { AuthWrapper, FormArea, FormWrapper, ImageWrapper, mailCodeWrapper };
