import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nBackend from 'i18next-http-backend';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: Cookies.get('Accept-Language') || 'tr',
    lng: Cookies.get('Accept-Language') || 'tr',
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${window.location.origin}/i18n/{{lng}}.json`
    }
  });

export default i18n;
