import React from 'react';

function DocumentsIcon() {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 6.25H6.95406C5.98757 6.25 6.25 5.4665 6.25 4.5V1M3.625 12.375H8.875M3.625 9.75H6.25M11.5 6.09987V13.25C11.5 14.2165 11.5 15 11.5 15H2.75C1.26256 15 1.00001 15 1.00001 15L1 1H1.26256H6.40013C6.86425 1 7.30937 1.18437 7.63756 1.51256L10.9874 4.86244C11.3156 5.19063 11.5 5.63574 11.5 6.09987Z"
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { DocumentsIcon };
