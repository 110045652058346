import styled from 'styled-components';

import { media } from '../../style/helper';

const StyledTextInput = styled.div`
  margin: ${props => props.margin || '0'};
  width: ${props => props.width || 'auto'};

  div {
    position: relative;
    svg {
      left: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  input {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    font-weight: 500;
    min-height: 44px;
    /* padding-left: ${props => (props.icon === undefined ? '14px' : '50px')}; */
    padding: 8.5px 0px;
    width: 100%;
  }
  ${media.phone`
    width: ${props => (props.mobileWidth ? props.mobileWidth : 'auto')}
  `}
`;

export { StyledTextInput };
