import React from 'react';

function BirthDayIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 122.88 108.61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.88,73.1v17.67H9.22V73.8c3.78,1.51,7.5,1.92,11.04,1.28c4.31-0.78,8.25-3.09,11.66-6.84 c4.54,4.81,9.34,7.13,14.39,7.08c5.11-0.06,10.23-2.59,15.32-7.49c5.5,5.28,10.83,7.63,15.98,7.35c4.88-0.26,9.37-2.84,13.51-7.46 c4.35,5.32,9.21,7.79,14.51,7.66C108.33,75.29,111.08,74.52,113.88,73.1L113.88,73.1L113.88,73.1z M1.65,97.58h119.58 c0.91,0,1.65,0.74,1.65,1.65v7.74c0,0.9-0.74,1.65-1.65,1.65H1.65c-0.9,0-1.65-0.74-1.65-1.65v-7.74C0,98.32,0.74,97.58,1.65,97.58 L1.65,97.58z M54.12,40.72V25.39c0-1.57,1.29-2.85,2.85-2.85h8.95c1.57,0,2.85,1.29,2.85,2.85v15.33H54.12L54.12,40.72z M61.45,0 c1.54,6.85,6.19,10.28,6.19,13.72c0,3.43-1.54,6.85-6.19,6.85c-4.63,0-6.19-3.43-6.19-6.85C55.26,10.3,59.89,6.87,61.45,0L61.45,0 L61.45,0z M18.37,45.39h86.23c5.47,0,9.94,4.48,9.94,9.94v7.55c-3.77,3.13-7.25,4.8-10.43,4.89c-3.96,0.1-7.74-2.44-11.32-7.97l0,0 c-0.2-0.29-0.43-0.56-0.74-0.78c-1.32-0.96-3.16-0.67-4.11,0.65c-3.61,4.95-7.32,7.71-11.13,7.92c-3.86,0.21-8.17-2.15-13-7.44 c-0.07-0.06-0.13-0.13-0.19-0.19c-1.21-1.1-3.07-1.01-4.17,0.19c-4.52,4.96-8.76,7.54-12.69,7.58c-3.84,0.04-7.72-2.38-11.62-7.41 l0,0c-0.17-0.23-0.39-0.43-0.64-0.61c-1.33-0.94-3.18-0.62-4.11,0.71c-2.88,4.09-6.24,6.51-9.85,7.16 c-2.48,0.44-5.14,0.02-7.98-1.15c-5.87-2.4-3.74-3.77-4.13-11.09C8.13,49.88,12.9,45.39,18.37,45.39L18.37,45.39L18.37,45.39z M114.54,70.15v0.32h-0.52C114.19,70.38,114.36,70.27,114.54,70.15L114.54,70.15L114.54,70.15z"
        stroke="#000"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { BirthDayIcon };
