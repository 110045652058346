import React from 'react';

function EmailIcon({ stroke }) {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3332 2.94958V11.2829C18.3332 11.8355 18.1137 12.3654 17.723 12.7561C17.3323 13.1468 16.8024 13.3663 16.2498 13.3663H3.74984C3.1973 13.3663 2.6674 13.1468 2.2767 12.7561C1.886 12.3654 1.6665 11.8355 1.6665 11.2829V2.94958"
        stroke={stroke ? stroke : '#969696'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 2.94954C18.3332 2.39701 18.1137 1.86711 17.723 1.47641C17.3323 1.0857 16.8024 0.866211 16.2498 0.866211H3.74984C3.1973 0.866211 2.6674 1.0857 2.2767 1.47641C1.886 1.86711 1.6665 2.39701 1.6665 2.94954L8.89567 7.46343C9.22678 7.67038 9.60938 7.7801 9.99984 7.7801C10.3903 7.7801 10.7729 7.67038 11.104 7.46343L18.3332 2.94954Z"
        stroke={stroke ? stroke : '#969696'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { EmailIcon };
