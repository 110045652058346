import { media } from '../../style/helper';
import styled from 'styled-components';

const StyledTypography = styled.div`
  color: ${props => props.color || 'black'};
  display: ${props => (props.inline ? 'inline' : 'block')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '1rem')};
  font-weight: ${props => props.fontWeight || 'normal'};
  letter-spacing: ${props => props.letterSpacing || '0'};
  line-height: ${props => props.lineHeight || '1.5'};
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  text-align: ${props => props.textAlign || 'left'};
  text-decoration-color: ${props => props.color || 'black'};
  text-transform: ${props => props.textTransform || 'none'};
  ${media.phone`
    font-size: ${props =>
      props.phoneFontSize
        ? `${props.phoneFontSize}px`
        : props.fontSize
        ? `${props.fontSize}px`
        : '1rem'}
  `}
`;

export { StyledTypography };
