import styled from 'styled-components';

import { media } from '../../style/helper';

export const ShipmentDetailWrap = styled.div`
  margin-top: 30px;
  ${media.phone`
  padding:0px 20px;
  `};
  .shipment-item.phone {
    display: none;
  }
  .shipment-item {
    background-color: #f7f7f7;
    border: 1px solid #dfdfdf;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    margin-top: 20px;
    .shipment-type {
      align-items: center;
      background-color: #f08326;
      border-top-left-radius: 5px;
      display: flex;
      padding: 0px 11.5px;
      ${media.phone` padding: 0px 5px;`}
    }
    .shipment-type.completed {
      background-color: #15c183;
    }
    .shipment-infos {
      display: flex;
      @media (max-width: 992px) {
        flex-direction: column;
        width: 100%;
      }
      @media (max-width: 767px) {
        flex-direction: row;
      }

      .shipment-info {
        display: flex;
        gap: 50px;
        margin: 11px 21px 0px 16px;
        padding-bottom: 11px;
        @media (max-width: 992px) {
          gap: 30px;
        }

        .no-info {
          @media (max-width: 992px) {
            flex-direction: column;
          }
        }
      }
      .route-info {
        position: relative;
      }
    }
    .shipment-status {
      align-items: center;
      display: flex;
      gap: 11px;
      justify-content: center;
      margin-left: 50px;
    }
    .shipment-buttons {
      align-items: center;
      display: flex;
      gap: 18px;
      margin-left: auto;
      padding-right: 27px;
      ${media.phone`
    margin-left: 0px;
    padding-right: 0;
    /* margin-bottom:16px; */
    `}
    }
    .shipment-show-detail {
      align-items: center;
      display: flex;
      gap: 10px;
      margin-left: auto;
      margin-right: 37px;
      ${media.phone`
        margin:10px auto;
      `};
    }
  }
  .shipment-dropdown-detail {
    border: 1px solid #dfdfdf;
    height: 0px;
    min-height: 0px;
    overflow: hidden;
    transition: all 0s ease;
    .dropdown-header {
      border-bottom: 1px solid #dfdfdf;
      display: flex;
      padding: 14px;
      padding-bottom: 13px;
      gap: 20px;
      ${media.phone`
      flex-wrap: wrap;
      gap: 0px;
      padding-bottom: 0px;
      justify-content: space-between;
      `}

      .item {
        padding-left: 20px;
        padding-right: 20px;
        border-left: 1px solid #dfdfdf;
      }
      .item:first-child {
        border-left: none;
        padding-left: 0px;
      }

      .item-title {
        align-items: center;
        display: flex;
        gap: 10px;
      }
      ${media.phone`
          .item{
            padding-right: 0px;
            width: 66%;
            border-left: none;
            padding-left: 0;
            margin: 5px 0px;
            box-sizing: border-box;
            border-bottom:1px solid #dfdfdf;
            position: relative;
            padding-bottom: 16px;
              
          }
          .item:nth-child(even){
            width: 34%;
          }
          .item:nth-child(even):after{
            // right 1px width 100% border
            content: "";
            display: block;
            width: 1px;
            height: 80%;
            background-color: #dfdfdf;
            position: absolute;
            right: 0;
            top: 0;

          }
          .item:nth-child(odd){
            padding-left: 18px;
          }
          .item:last-child:after{
            display: none;
          }
          
          .item.hbl-no,.item.mbl-no{
            border-bottom: none;
          }
          .item.final-destination{
            width: 100%;
            border-bottom: 1px solid #dfdfdf;
            padding-left:0px;
            padding-bottom: 14px;
          }

          div{
            text-align:left;
        /* padding-left:20px; */
        margin-left:0px;

          }
         
        `};
    }
  }
  .shipment-dropdown-detail.visible {
    min-height: 300px;
    height: 100%;
    transition: all 0.6s ease;
  }
  .dropdown-note {
    padding: 14px;
  }
  .timeline-container {
    width: 100%;
  }

  .timeline {
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 20px;
    gap: 45px;
    position: relative;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
      gap: 70px;
    }
  }

  .timeline.mobile {
    @media (max-width: 992px) {
      display: flex;
    }
  }

  .event {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: right;
    position: relative;

    &::after {
      content: '';
      width: 182px;
      height: 1px;
      background-color: #15c183;
      position: absolute;
      right: -121px;
      top: 41%;

      @media (max-width: 1025px) {
        height: 100px;
        width: 1px;
        right: 50%;
        top: 93px;
      }
      @media (max-width: 767px) {
        height: 115px;
        width: 1px;
        right: 50%;
        top: 25px;
      }
    }
    @media (min-width: 1025px) {
      &:nth-last-child(2)::after {
        right: -96px;
      }
    }
    &:last-child::after {
      display: none;
    }
  }

  ${media.phone`
    .event-web{
      display: none;

    }
    `}
  .event-mobile {
    display: none;
    ${media.phone`
      display: flex;
      width: 100%;
      .details{
        display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .location{
      margin-left: 40px;

    }
        .details-right{
          display: flex;
    justify-content: center;
    margin-left: 8px;
    justify-content: start;
    min-width: 112px;
    .detail-text-wrapper{
      min-width: 125px;
    }
   .detail-text{
    max-width: 125px;
   }
    .circle{
      position:absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
    }
        }
  }
    `}
  }
  .terminal {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;

    margin-left: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #15c183;
  }

  .line {
    background-color: #d3d3d3;
    flex: 1;
    height: 2px;
    position: relative;
    z-index: 0;
  }
  .container-list {
    margin-top: 30px;
    .container-item {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      margin-bottom: 20px;
      @media (max-width: 787px) {
        display: flex;
        position: relative;
        &:after {
          /* content: ''; */
          position: absolute;
          top: 0;
          right: 0;
          width: 55px;
          height: 100%;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          pointer-events: none;
        }
        .container-wrapper {
          overflow: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .container-header {
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        padding: 14px;
        justify-content: space-between;
        @media (min-width: 992px) {
          display: none;
        }
      }
      .container-header-left {
        align-items: center;
        display: flex;
        gap: 10px;
      }
      .container-content {
        display: flex;
        justify-content: space-between;
        .arrival-date-title {
          align-items: center;
          display: flex;
          gap: 10px;
          margin-bottom: 5px;
        }
        .container-show-detail {
          align-items: flex-end;
          display: flex;
          cursor: pointer;

          div {
            text-decoration: underline;
          }
        }
        padding: 14px;
      }
      .container-details {
        /* border-top: 1px solid #dfdfdf; */
        display: flex;
        /* gap: 25px; */
        /* padding: 14px; */
        ${media.phone`
           align-items: center;
           padding-left:12px;
          `}

        @media (min-width: 992px) {
          .container-detail-item div {
            margin-right: 24px;
            margin-left: 19px;
          }
          .container-detail-item:nth-child(2) div {
            margin-left: 12px;
          }
          .container-detail-item:last-child {
            border-right: none;
          }
        }
        .container-detail-item {
          border-right: 1px solid #ddd;
          /* padding: 10px 10px; */
          margin: 10px 0px;
          ${media.phone`
          border-right: none;
          border-left: 1px solid #ddd;
          `}
        }
        .container-detail-item:first-child {
          border: none;
          background-color: #f7f7f7;
          margin: 0;
          padding: 20px 0 20px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          ${media.phone`
          display: none;
          `}
        }
        .container-hr {
          display: none;
        }
        ${media.phone`
    
          .container-detail-item {
            width: 100%;
            border-left: none;
            padding-left: 10px;
            min-width: 115px;
            border-right: 1px solid #ddd;
          }
          .container-hr{
            display: none;
            width: 95%;
            margin: 0 auto;
            color:#dfdfdf;
            border: 1px solid #dfdfdf;
          }
        
          .container-detail-item:nth-child(2) {
            /* border-left: 1px solid #ddd; */
          }
          .container-detail-item:nth-child(4) {
            /* border-right: 1px solid #ddd; */

          }
           
          .container-detail-item:last-child {
            width: 100%;
          }
        `}
      }
    }
  }
  ${media.phone`
    .shipment-item.web{
      display: none;
    }
    .shipment-item.phone{
      display: flex;
    }
     .shipment-infos {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    .shipment-favorite-phone{
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .shipment-info {
      width: 39%;
    }
    .shipment-info.start-arrival{
      width: 100%;
    }
        }

  `};
`;
