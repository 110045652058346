import React from 'react';

function ArrowUpIcon() {
  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5186 1L7.51855 7L1.51855 0.999999"
        stroke="#1B1B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { ArrowUpIcon };
