import React from 'react';

function ShipIcon({ stroke, width, height }) {
  return (
    <svg
      width={width ? width : '26px'}
      height={height ? height : '24px'}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39639 17.2888L3.98309 22.8727C4.14682 23.2262 4.50091 23.4524 4.89046 23.4524H21.1608C21.5505 23.4524 21.9048 23.226 22.0684 22.8723L24.6075 17.3846C24.8284 16.9071 24.6416 16.3401 24.1801 16.0875L13.5012 10.2417C13.2046 10.0793 12.8462 10.0778 12.5483 10.2376L1.83102 15.9872C1.36439 16.2376 1.17381 16.8083 1.39639 17.2888Z"
        stroke={stroke ? stroke : 'white'}
        strokeLinecap="round"
      />
      <path
        d="M4.97363 13.7968V8.92105C4.97363 8.50318 5.23347 8.12937 5.62514 7.98374L12.6768 5.36199C12.9016 5.27841 13.1489 5.27841 13.3737 5.36199L20.4253 7.98374C20.817 8.12937 21.0769 8.50318 21.0769 8.92105V13.7968"
        stroke={stroke ? stroke : 'white'}
        strokeLinecap="round"
      />
      <path
        d="M11.5811 4.04688V1.3C11.5811 1.13431 11.7154 1 11.8811 1H14.223C14.3887 1 14.523 1.13431 14.523 1.3V4.04688"
        stroke={stroke ? stroke : 'white'}
        strokeLinecap="round"
      />
      <path
        d="M6.52246 9.67126L7.96762 9.10352"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M15.6576 8.0079L17.081 8.62833"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path d="M13.0259 10.7969V12.0966" stroke={stroke ? stroke : 'white'} strokeLinecap="round" />
      <path d="M13.0259 13.7969V23.0903" stroke={stroke ? stroke : 'white'} strokeLinecap="round" />
      <path
        d="M9.05127 8.58727L10.4964 8.01953"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M18.1703 9.1329L19.5937 9.75333"
        stroke={stroke ? stroke : 'white'}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </svg>
  );
}

export { ShipIcon };
