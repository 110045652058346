import styled from 'styled-components';

import { media } from '../../../style/helper';

export const ShipmentListWrap = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 32px;
  .shipment-list-phone {
    display: none;
  }
  ${media.phone`
  .shipment-list-phone {
    display: block;
  }
  .shipment-list-web{
    display:none;
  }
  `}
  .shipment-list-item {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 27px 23px;
    width: 50%;
    ${media.phone`
      width:100%;`}
    .shipment-list-item-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      .shipment-list-header-title {
        align-items: center;
        display: flex;
        gap: 10px;
      }
    }
    .shipment-item {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      display: flex;
      margin-bottom: 24px;
      cursor: pointer;
      .shipment-item-type {
        align-items: center;
        background-color: #f08326;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        padding: 0px 14px;
      }
      .shipment-item-type.completed {
        background-color: #15c183;
      }
      .shipment-item-info {
        margin-left: 16px;
        padding: 12px 0px;
      }
      .shipment-item-arrival-date {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin: 12px 0px;
        margin-left: 12px;
        padding-left: 12px;
        border-left: 1px solid #dfdfdf;

        ${media.phone`
        border-left:none;
        padding-left:12px;
        margin:0px;
        `}
      }
      .show-shipment-detail {
        align-items: center;
        display: flex;
        margin-left: auto;
        padding: 0px 23px;
      }
    }
    span.swiper-pagination-bullet {
      height: 12px;
      margin-top: 13px;
      width: 12px;
    }
    .swiper-pagination-bullet-active {
      background-color: #1b1b1b;
    }
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0px;
    }
  }
  ${media.phone`
      flex-direction:column;
      padding:0px 20px;
      .shipment-list-item{
        padding:0px;
        border:none;
        .shipment-infos-phone{
          margin-bottom:10px;
          width:75%;
          .shipment-item-info { 
          border-bottom:1px solid #DFDFDF;
         }
          .shipment-item-arrival-date { 
        padding: 12px 16px; 
         }
        }
      }
    `}
`;
// export {  };
