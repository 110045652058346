import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShipmentService from '../../service/shipments';
import { Button } from '../Button';
import { DocumentsIcon } from '../Icons';
import { DownloadIcon } from '../Icons/DownloadIcon';
import { Modal } from '../Modal';
import { Typography } from '../Typography';

import * as S from './style';
function ShipmentDocumentModal({ selectedShipment, setSelectedShipment }) {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  useEffect(() => {
    if (selectedShipment?.shipment_code) {
      setDocumentsLoading(true);
      ShipmentService.getShipmentDocuments(selectedShipment?.shipment_code)
        .then(res => {
          setDocuments(res ?? []);
        })
        .finally(() => {
          setDocumentsLoading(false);
        });
    }
  }, [selectedShipment]);

  return (
    <div>
      <Modal
        isLoading={documentsLoading}
        width={800}
        isOpen={!!selectedShipment}
        onClose={() => setSelectedShipment(null)}
      >
        {documentsLoading && (
          <div style={{ textAlign: 'center', margin: '32px 0' }}>
            <img src={'/images/route_loader.gif'} alt="Logo" className="loader-logo" />
          </div>
        )}

        {!documentsLoading && (
          <S.HeaderWrapper>
            <h2 className="modal-title">{t('documents')}</h2>
            <div className="flex">
              <h2 className="modal-desc">{t('bookingNumber')}: </h2>
              <h2 className="booking-modal">{selectedShipment?.shipment_number}</h2>
            </div>
          </S.HeaderWrapper>
        )}

        {!documentsLoading && documents?.length === 0 && (
          <Typography textAlign={'center'} fontSize={20} color={'#D71A21'}>
            {t('cantFindDocument')} 🔴
          </Typography>
        )}
        {!documentsLoading && (
          <S.DocumentsWrapper>
            {documents?.map((item, index) => (
              <div key={index} className="document-item">
                <div className="doc-icon">
                  <DocumentsIcon />
                </div>
                <div className="name-wrapper">
                  <h3 className="document-name">{item?.type}</h3>
                </div>
                <div
                  className="date-wrapper"
                  style={{
                    margin: 'auto'
                  }}
                >
                  <h3 className="document-name">
                    {dayjs(item?.created_at).format('DD MMMM YYYY')}
                  </h3>
                </div>
                <div className="download-btn">
                  <Button
                    backgroundColor={'#FFF'}
                    borderRadius={'25px'}
                    border={'1px solid #dfdfdf'}
                    color={'#1B1B1B'}
                    hoverBackgroundColor={'#FFF'}
                    hoverColor={'#1B1B1B'}
                    onClick={async () => {
                      try {
                        const req = await axios.get(
                          'https://api.orbislog.com/api/documents/' + item?.key,
                          {
                            responseType: 'blob'
                          }
                        );

                        if (req.status === 200) {
                          const urlBlob = window.URL.createObjectURL(new Blob([req.data]));
                          const link = document.createElement('a');
                          link.href = urlBlob;
                          link.setAttribute('download', `${item?.key}.pdf`);
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                        }
                      } catch (error) {
                        console.error('Document fetch error:', error);
                      }
                    }}
                  >
                    {t('downloadDocument')}
                    <DownloadIcon />
                  </Button>
                </div>
              </div>
            ))}
          </S.DocumentsWrapper>
        )}
      </Modal>
    </div>
  );
}

export { ShipmentDocumentModal };
