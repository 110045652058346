import styled from 'styled-components';

import { media } from '../../style/helper';

export const Dropdown = styled.div`
  background: white;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000004d;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  .notifications-header {
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    padding: 16px 14px;
  }
  .notifications {
    max-height: 400px;
    overflow: auto;
    padding: 14px;
    .notification {
      align-items: center;
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      /* justify-content: space-between; */
      gap: 15px;
      margin-bottom: 17px;
      .noti-type {
        align-items: center;
        background-color: #F08326;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        padding: 15px 10px;
      }

      .noti-message {
        border: 1px solid;
        border-radius: 40px;
        margin: 0px 10px;
        margin-left: auto;
        padding: 0px 10px;
        span {
          align-items: center;
          display: flex;
          gap: 5px;
          padding: 8px;
        }
      }
    }
  }
`;
export const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 768px) {
    display: flex;
  }
  div {
    background: black;
    height: 3px;
    width: 25px;
  }
`;
// export {  };
export const HeaderWrapper = styled.div`
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  padding: 0px 132px;
  ul {
    align-items: center;
    display: flex;
    gap: 35px;
    height: 100%;
    position: relative;
    .nav-user {
      align-items: center;
      display: flex;
      gap: 15px;
    }
    li {
      font-weight: 500;
      position: relative;
    }
    li.active a {
      /* text-decoration: underline; */
      border-bottom: 1px solid;
    }
  }
  .mobile-navbar {
    display: none;
  }
  .header-navbar {
    position: relative;
  }
  ${media.phone`
  justify-content:space-between;
  border-bottom: none;

  padding: 0px 25px;
  position: relative;
  .header-navbar{
    display: flex;
    align-items: center;
    position: unset;
  }
  .mobile-navbar {
    display: flex;
    gap: 18px;
  }
  .web-menu {
    display: none;
  }`}
`;
export const NotificationsBadge = styled.span`
  background: #15c183;
  border: 1px solid #fff;
  border-radius: 100%;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  position: absolute;
  right: -2px;
  top: -6px;
`;
export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;
export const SidePanel = styled.div`
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  /* display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}; */
  height: 100%;
  padding: 20px;
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? '0' : '-800px')};
  top: 0;
  transition: right 0.4s ease;
  width: 601px;
  z-index: 1000;
  .panel-header {
    span {
      cursor: pointer;
      float: right;
      margin-bottom: 35px;
    }
  }
  .panel-user-info {
    border-bottom: 1px solid #dfdfdf;
    margin: 0px 57px;
    padding-bottom: 27px;
    .user-info {
      display: flex;
      gap: 14px;
      margin-top: 26px;
    }
  }
  .panel-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0px 57px;
    ${media.phone`
      flex-direction:column;
      `}
    button {
      margin-top: 27px;
      ${media.phone`
      justify-content:center;
      `}
    }
  }
  ${media.phone`
    width: 100%;
  }`}
`;
export const SidePanelMenu = styled.div`
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? '0' : '-800px')};
  top: 0;
  transition: right 0.4s ease;
  width: 100%;
  z-index: 1000;
  .panel-header {
    margin-bottom: 35px;
    margin-right: 21px;
    margin-top: 30px;
    span {
      cursor: pointer;
      float: right;
    }
  }
  .mobile-menu {
    align-items: flex-start;
    flex-direction: column;
    gap: 21px;
    margin-left: 21px;
    margin-top: 50px;
  }
  @media (min-width: 720px) {
    display: none;
  }
`;
