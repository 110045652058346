import React, { useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { CompletedIcon, InProgressIcon, RightArrowIcon } from '@/components/Icons';
import { ShipIcon } from '@/components/Icons/ShipIcon';
import { Loader } from '@/components/Loader';
import { Typography } from '@/components/Typography';

import ShipmentService from '../../../service/shipments';

import * as S from './style';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function ShipmentList() {
  const [completedShipmentList, setCompletedShipmentList] = useState([]);
  const [inProgressShipmentList, setInProgressShipmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const getShipmentList = async () => {
    setIsLoading(true);
    try {
      const req = await ShipmentService.getAll({
        page: 1,
        perPage: 5,
        status: 'active'
      });
      const reqDone = await ShipmentService.getAll({
        page: 1,
        perPage: 5,
        status: 'done'
      });
      setInProgressShipmentList(req.data);
      setCompletedShipmentList(reqDone.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getShipmentList();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <S.ShipmentListWrap>
      <div className="shipment-list-item">
        <div className="shipment-list-item-header">
          <div className="shipment-list-header-title">
            <InProgressIcon />
            <Typography fontWeight={200} fontSize={24} phoneFontSize={18}>
              {t('activeShipments')}
            </Typography>
          </div>
          <a
            href="/shipments?status=active"
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <Typography fontWeight={500} phoneFontSize={13}>
              {t('allList')}
            </Typography>
            <RightArrowIcon stroke={'black'} />
          </a>
        </div>
        <div className="shipment-list-web">
          {inProgressShipmentList?.map((item, index) => (
            <div
              className="shipment-item"
              key={index}
              onClick={() => {
                window.location.href = `/shipment-detail/${item.shipment_code}`;
              }}
            >
              <div className="shipment-item-type">
                <ShipIcon />
              </div>
              <div className="shipment-item-info">
                <Typography fontWeight={300} fontSize={14}>
                  {' '}
                  {t('bookingNumber')}
                </Typography>
                <Typography fontWeight={400} fontSize={22} lineHeight={0}>
                  {item.shipment_number}
                </Typography>
              </div>
              <div className="vertical-line"></div>

              <div className="shipment-item-arrival-date">
                <Typography fontWeight={300} fontSize={14}>
                  {t('eta')}
                </Typography>
                <Typography
                  style={{
                    marginTop: '5px'
                  }}
                  fontWeight={400}
                  fontSize={16}
                  lineHeight={0}
                >
                  {dayjs(item.shipment_date).format('DD MMMM YYYY')}
                </Typography>
              </div>
              <div className="show-shipment-detail">
                <a href={`/shipment-detail/${item.shipment_code}`}>
                  <RightArrowIcon stroke={'#969696'} />
                </a>
              </div>
            </div>
          ))}

          {inProgressShipmentList?.length === 0 && <div>{t('noActiveShipmentFound')}</div>}
        </div>
        <div className="shipment-list-phone">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {inProgressShipmentList?.map((item, index) => (
              <SwiperSlide
                onClick={() => {
                  window.location.href = `/shipment-detail/${item.shipment_code}`;
                }}
                key={index}
              >
                <div className="shipment-item">
                  <div className="shipment-item-type">
                    <ShipIcon />
                  </div>
                  <div className="shipment-infos-phone">
                    <div className="shipment-item-info">
                      <Typography fontSize={14}> {t('bookingNumber')}</Typography>
                      <Typography fontWeight={400} fontSize={22} lineHeight={0}>
                        {item.shipment_number}
                      </Typography>
                    </div>
                    <div className="shipment-item-arrival-date">
                      <Typography fontSize={14}> {t('eta')}</Typography>
                      <Typography fontWeight={400} fontSize={16} lineHeight={0}>
                        {dayjs(item.shipment_date).format('DD MMMM YYYY')}
                      </Typography>
                    </div>
                  </div>
                  <div className="show-shipment-detail">
                    <a href={`/shipment-detail/${item.shipment_code}`}>
                      <RightArrowIcon stroke={'#969696'} />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {inProgressShipmentList?.length === 0 && <div>{t('noActiveShipmentFound')}</div>}
        </div>
      </div>
      <div className="shipment-list-item">
        <div className="shipment-list-item-header">
          <div className="shipment-list-header-title">
            <CompletedIcon />
            <Typography fontWeight={200} fontSize={24} phoneFontSize={18}>
              {t('arrivedShipments')}
            </Typography>
          </div>
          <a
            href="/shipments?status=done"
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <Typography fontWeight={500} phoneFontSize={13}>
              {t('allList')}
            </Typography>
            <RightArrowIcon stroke={'black'} />
          </a>
        </div>
        <div className="shipment-list-web">
          {completedShipmentList?.map((item, index) => (
            <div
              className="shipment-item"
              key={index}
              style={{
                borderColor: '#15C183'
              }}
              onClick={() => {
                window.location.href = `/shipment-detail/${item.shipment_code}`;
              }}
            >
              <div className="shipment-item-type completed">
                <ShipIcon />
              </div>
              <div className="shipment-item-info">
                <Typography fontSize={14}>{t('bookingNumber')}</Typography>
                <Typography fontWeight={400} fontSize={22} lineHeight={0}>
                  {item.shipment_number}
                </Typography>
              </div>
              <div className="shipment-item-arrival-date">
                <Typography fontSize={14}>{t('eta')}</Typography>
                <Typography fontWeight={400} fontSize={16} lineHeight={0}>
                  {dayjs(item.shipment_date).format('DD MMMM YYYY')}
                </Typography>
              </div>
              <div className="show-shipment-detail">
                <a href={`/shipment-detail/${item.shipment_code}`}>
                  <RightArrowIcon stroke={'#969696'} />
                </a>
              </div>
            </div>
          ))}
          {completedShipmentList?.length === 0 && <div>{t('noDoneShipment')}</div>}
        </div>
        <div className="shipment-list-phone">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {completedShipmentList?.map((item, index) => (
              <SwiperSlide
                onClick={() => {
                  window.location.href = `/shipment-detail/${item.shipment_code}`;
                }}
                key={index}
              >
                <div className="shipment-item">
                  <div className="shipment-item-type completed">
                    <ShipIcon />
                  </div>
                  <div className="shipment-infos-phone">
                    <div className="shipment-item-info">
                      <Typography fontSize={14}> {t('bookingNumber')}</Typography>
                      <Typography fontWeight={400} fontSize={22} lineHeight={0}>
                        {item.shipment_number}
                      </Typography>
                    </div>
                    <div className="shipment-item-arrival-date">
                      <Typography fontSize={14}> {t('eta')}</Typography>
                      <Typography fontWeight={400} fontSize={16} lineHeight={0}>
                        {dayjs(item.shipment_date).format('DD MMMM YYYY')}
                      </Typography>
                    </div>
                  </div>
                  <div className="show-shipment-detail">
                    <a href={`/shipment-detail/${item.shipment_code}`}>
                      <RightArrowIcon stroke={'#969696'} />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {completedShipmentList?.length === 0 && <div>{t('noDoneShipment')}</div>}
        </div>
      </div>
    </S.ShipmentListWrap>
  );
}

export { ShipmentList };
