import styled from 'styled-components';

const ContainerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  min-height: 75vh;
  padding: 0 20px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export { ContainerWrapper };
