import React from 'react';

import * as S from './style';
import { t } from 'i18next';
function Footer() {
  return (
    <S.FooterWrapper>
      <div className="footer-first-container">
        <div className="Footer-logo">
          <img src="/images/orbis-logo-dark.svg" height={'100%'} />
        </div>
        <div className="Footer-navbar">
          <ul>
            <li className="active">
              <a href={t('contactLink')} target="_blank" rel="noreferrer ">
                {t('contact')}
              </a>{' '}
              <a href={t('aboutLink')} target="_blank" rel="noreferrer ">
                {t('about')}
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>

      <div
        className="footer-copy-right"
        style={{
          textAlign: 'center',
          padding: '20px 0',
          color: '#676767'
        }}
      >
        ©️ 2024 Orbis Route. Tüm Hakları Saklıdır.
      </div>
    </S.FooterWrapper>
  );
}

export { Footer };
