/* eslint-disable react/no-children-prop */
import { useState } from 'react';
// import Select from 'react-select';

import { CountrySelect, StateSelect } from 'react-country-state-city';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import { Button, Typography } from '@/components';
import { BackIcon } from '@/components/Icons';
import { RightArrowIcon } from '@/components/Icons/RightArrowIcon';
import LanguageDropdown from '@/components/LanguageDropdown';
import { Modal } from '@/components/Modal';
import { TextInput } from '@/components/TextInput';

import 'react-country-state-city/dist/react-country-state-city.css';
import * as S from './style';

import axios from 'axios';

function SignUpPage() {
  const [isSubmitMail, setIsSubmitMail] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState('client');
  const [errorText, setErrorText] = useState('');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [surname, setSurname] = useState('');
  const [checkUserTerms, setCheckUserTerms] = useState(false);
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { t } = useTranslation();

  const handleSignUp = () => {
    try {
      if (!checkUserTerms) {
        setErrorText(t('acceptTerms'));
        return;
      }
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/register`,
          {
            name: name,
            surname: surname,
            email: email,
            country: country,
            city: city,
            companyName: companyName,
            phoneNumber: phoneNumber,
            companyPhone: phoneNumber,
            role: selectedUserType,
            numberExtension: '90'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        )
        .then(async () => {
          setIsSubmitMail(true);
          setErrorText('');
        })
        .catch(err => {
          setErrorText(err.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  return (
    <S.AuthWrapper>
      <S.ImageWrapper>
        <a href="/">
          <img src="./images/auth-image.svg" alt="Orbis Auth" width={'100%'} height={'100%'} />
        </a>
      </S.ImageWrapper>

      <S.FormWrapper className="auth-content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 20px'
          }}
        >
          <a href="/">
            <img src="./images/orbis-logo.svg" alt="Orbis Logo" width={80} height={80} />
          </a>
          <div style={{}}>
            <LanguageDropdown hasText />
          </div>
        </div>
        {!isSubmitMail && (
          <S.FormArea>
            <span onClick={() => (window.location.href = '/')} style={{ cursor: 'pointer' }}>
              <BackIcon />
            </span>
            <Typography fontSize={42} fontWeight={300} margin={'0px 0px 11px 0px'}>
              {t('signUp')}
            </Typography>
            {errorText && (
              <Typography
                fontSize={16}
                fontWeight={500}
                color={'#B43E3B'}
                margin={'0px 0px 11px 0px'}
              >
                {errorText}
              </Typography>
            )}
            <div className="user-type">
              <Typography fontSize={18} fontWeight={600} margin={'37px 0px 17px 0px'}>
                {t('userType')}
              </Typography>
              <div className="user-type-buttons">
                <Button
                  backgroundColor={'#FFFFFF'}
                  color={'#1b1b1b'}
                  border={`1px solid  ${selectedUserType === 'agency' ? '#15C183' : '#DFDFDF'} `}
                  width={'50%'}
                  hoverBackgroundColor={'#FFFFFF'}
                  onClick={() => setSelectedUserType('agency')}
                  hoverColor={'#1b1b1b'}
                >
                  {t('agency')}
                </Button>
                <Button
                  backgroundColor={'#FFFFFF'}
                  color={'#1b1b1b'}
                  border={`1px solid  ${selectedUserType === 'client' ? '#15C183' : '#DFDFDF'} `}
                  width={'50%'}
                  onClick={() => setSelectedUserType('client')}
                  hoverBackgroundColor={'#FFFFFF'}
                  hoverColor={'#1b1b1b'}
                >
                  {t('client')}
                </Button>
              </div>
            </div>
            <div className="form-group">
              <TextInput
                placeHolder={t('enterName')}
                margin={'15px 0px 0px  0px'}
                label={t('name')}
                width={'50%'}
                type={'text'}
                onChange={e => setName(e.target.value)}
              />
              <TextInput
                placeHolder={t('enterSurname')}
                margin={'15px 0px 0px  0px'}
                label={t('surname')}
                width={'50%'}
                type={'text'}
                onChange={e => setSurname(e.target.value)}
              />
            </div>

            <div className="form-group">
              <TextInput
                placeHolder={t('enterCompanyName')}
                margin={'15px 0px 0px  0px'}
                label={t('companyName')}
                width={'100%'}
                type={'text'}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="email-input" style={{
                width: '50%'
              }}>
                <TextInput
                  placeHolder={t('enterBusinessEmail')}
                  margin={'15px 0px 0px  0px'}
                  label={t('businessEmail')}
                  width={'100%'}
                  type={'text'}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="form-phone">
                <Typography fontSize={16} fontWeight={500}>
                  {t('phoneNumber')}
                </Typography>
                <PhoneInput
                  inputProps={{ style: { width: '100%' } }}
                  country={'tr'}
                  value={phoneNumber}
                  onChange={phone => setPhoneNumber(phone)}
                />
              </div>
            </div>
            <div className="form-select-group">
              <div style={{ flex: 1 }}>
                <Typography fontSize={16} fontWeight={500}>
                  {t('country')}
                </Typography>
                <CountrySelect
                  onChange={e => {
                    setCountryid(e.id);
                    setCountry(e.name);
                  }}
                  showFlag={true}
                  placeHolder={t('selectCountry')}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Typography fontSize={16} fontWeight={500}>
                  {t('region')}
                </Typography>
                <StateSelect
                  countryid={countryid}
                  onChange={e => {
                    setCity(e.name);
                    setstateid(e.id);
                  }}
                  placeHolder={t('selectRegion')}
                />
              </div>
            </div>

            <div className="forgot-password">
              <div className="privacy-policy-check">
                <input
                  type="checkbox"
                  id="check"
                  onChange={e => {
                    setCheckUserTerms(e.target.checked);
                    setErrorText('');
                  }}
                />
                <label htmlFor="check" style={{ margin: '0px 9px', fontWeight: 'normal' }}>
                  <a
                    href={`${t('termsHref')}`}
                    target="_blank"
                    style={{
                      fontWeight: 'bold'
                    }}
                    rel="noreferrer"
                    // htmlFor="check"
                    // onClick={() => {
                    //   setModalContent({
                    //     title: t('termsTitle'),
                    //     content: t('termsContent')
                    //   });
                    //   setModalOpen(true);
                    // }}
                  >
                    {t('termsLink')}
                  </a>{' '}
                  {t('acceptTerms')}
                </label>
              </div>
            </div>
            <div className="submit-sign-in web">
              <Button borderRadius={'25px'} onClick={handleSignUp}>
                {t('signUp')} <RightArrowIcon />
              </Button>
            </div>
            <div className="submit-sign-in phone">
              <Button borderRadius={'25px'} onClick={handleSignUp}>
                {t('signUp')} <RightArrowIcon />
              </Button>
            </div>
          </S.FormArea>
        )}
        {isSubmitMail && (
          <S.mailCodeWrapper>
            <span onClick={() => (window.location.href = '/')} style={{ cursor: 'pointer' }}>
              <BackIcon />
            </span>
            <Typography fontSize={42} fontWeight={300} margin={'0px 0px 11px 0px'}>
              {t('thankYou')}
              <Typography margin={'11px 0px 0px 0px'}>
                {t('confirmationEmail')} {email} {t('afterVerification')}
              </Typography>
            </Typography>
          </S.mailCodeWrapper>
        )}

        <div className="auth-footer">
          <ul>
            <li className="active">
              <a href={t('contactLink')} target="_blank" rel="noreferrer ">
                {t('contact')}
              </a>{' '}
              <a
                href={t('aboutLink')}
                target="_blank"
                rel="noreferrer "
                style={{
                  marginLeft: '15px'
                }}
              >
                {t('about')}
              </a>{' '}
            </li>
          </ul>
        </div>
      </S.FormWrapper>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <h2>{modalContent.title}</h2>
        <p>{modalContent.content}</p>
      </Modal>
    </S.AuthWrapper>
  );
}

export { SignUpPage };
