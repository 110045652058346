import React from 'react';

import { SearchShipment, ShipmentList } from '@/components/HomePageComponents';

const Home = () => {
  return (
    <>
      <SearchShipment />
      <ShipmentList />
    </>
  );
};

export default Home;
