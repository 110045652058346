import React from 'react';

import * as S from './style';
function Button({
  children,
  backgroundColor,
  color,
  fontSize,
  borderRadius,
  border,
  width,
  height,
  onClick,
  margin,
  padding,
  hoverBackgroundColor,
  fontWeight,
  hoverColor,
  disabled,
  className
}) {
  return (
    <S.StyledButton
      className={className}
      backgroundColor={backgroundColor}
      color={color}
      disabled={disabled}
      fontSize={fontSize}
      margin={margin}
      width={width}
      height={height}
      fontWeight={fontWeight}
      padding={padding}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverColor={hoverColor}
      border={border}
      onClick={() => onClick?.()}
      borderRadius={borderRadius}
    >
      {children}
    </S.StyledButton>
  );
}

export { Button };
