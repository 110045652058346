import React from 'react';

import * as S from './style';
import { Typography } from '../Typography';
import { CountdownTimer } from '../CountdownTimer';
function TextInput({
  type,
  placeHolder,
  onChange,
  icon,
  label,
  showCountdown = false,
  timeLeft,
  setTimeLeft,
  margin,
  width,
  mobileWidth,
  value
}) {
  return (
    <S.StyledTextInput margin={margin} width={width} mobileWidth={mobileWidth}>
      <div className="form-input">
        {icon !== undefined && icon}
        {label !== undefined && (
          <Typography fontSize={16} fontWeight={500}>
            {' '}
            {label}{' '}
          </Typography>
        )}
        <input
          onChange={e => onChange(e)}
          type={type}
          value={value}
          placeholder={placeHolder}
          style={{ paddingLeft: icon !== undefined ? '35px' : '14px' }}
        />
        {showCountdown && <CountdownTimer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />}
      </div>
    </S.StyledTextInput>
  );
}

export { TextInput };
